import moment from "moment";
import { useFormContext } from "react-hook-form";
import { FormControl, FormLabel, HStack, Input, InputGroup, Text } from "@chakra-ui/react";

export const DateControl = () => {
  const { register, watch, getValues } = useFormContext();
  watch(["to", "from"]);

  const maxFrom = moment(getValues("to")) > moment("1970-01-01") ? getValues("to") : "";

  return (
    <FormControl>
      <FormLabel> <Text variant={"form_input_label"}> Дата </Text> </FormLabel>
      <HStack>
        <InputGroup>
          <Input
            {...register("from")}
            variant={"primary"}
            p={1}
            type="date"
            max={maxFrom}
            min={moment("1970-01-01").format("YYYY-MM-DD")}
            required={true}
          />
        </InputGroup>
        <Text>
          —
        </Text>
        <InputGroup>
          <Input
            {...register("to")}
            variant={"primary"}
            p={1}
            type="date"
            min={getValues("from")}
            max={moment().format("YYYY-MM-DD")}
            required={true}
          />
        </InputGroup>
      </HStack>
    </FormControl>
  );
};