import { Box } from "@chakra-ui/react";

import { useAppSelector } from "shared/model";
import { useDocuments } from "features/doc";
import { DocHeader, DocList, DocumentFilter, DocumentsTable } from "widgets/doc";

export function DocumentsList() {
  const company = useAppSelector(state => state.companies.current);

  if (!company) {
    return <Box>Компания не выбрана</Box>;
  }

  return (
    <DocList
      useData={useDocuments}
      // action={DocumentsActions}
      head={<DocHeader label={"Накладные"}><DocumentFilter /></DocHeader>}
      body={<DocumentsTable />}
      linkToMap={"/documents/docMap"}
    />
  );
}