import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export const SourceIcon = ({ w = 14, h = 14, ...props }: IconProps) => {
  return (
    <Icon viewBox={`0 0 ${w} ${h}`} {...props}>
      <path
        d="M13.3636 8.90909H12.0909V7C12.0909 6.83123 12.0239 6.66936 11.9045 6.55002C11.7852 6.43068 11.6233 6.36364 11.4545 6.36364H7.63636V5.09091H8.90909C9.07786 5.09091 9.23973 5.02386 9.35907 4.90452C9.47841 4.78518 9.54545 4.62332 9.54545 4.45455V0.636364C9.54545 0.467589 9.47841 0.305728 9.35907 0.186387C9.23973 0.0670452 9.07786 0 8.90909 0H5.09091C4.92213 0 4.76027 0.0670452 4.64093 0.186387C4.52159 0.305728 4.45455 0.467589 4.45455 0.636364V4.45455C4.45455 4.62332 4.52159 4.78518 4.64093 4.90452C4.76027 5.02386 4.92213 5.09091 5.09091 5.09091H6.36364V6.36364H2.54545C2.37668 6.36364 2.21482 6.43068 2.09548 6.55002C1.97614 6.66936 1.90909 6.83123 1.90909 7V8.90909H0.636364C0.467589 8.90909 0.305728 8.97614 0.186387 9.09548C0.0670452 9.21482 0 9.37668 0 9.54545V13.3636C0 13.5324 0.0670452 13.6943 0.186387 13.8136C0.305728 13.933 0.467589 14 0.636364 14H4.45455C4.62332 14 4.78518 13.933 4.90452 13.8136C5.02386 13.6943 5.09091 13.5324 5.09091 13.3636V9.54545C5.09091 9.37668 5.02386 9.21482 4.90452 9.09548C4.78518 8.97614 4.62332 8.90909 4.45455 8.90909H3.18182V7.63636H10.8182V8.90909H9.54545C9.37668 8.90909 9.21482 8.97614 9.09548 9.09548C8.97614 9.21482 8.90909 9.37668 8.90909 9.54545V13.3636C8.90909 13.5324 8.97614 13.6943 9.09548 13.8136C9.21482 13.933 9.37668 14 9.54545 14H13.3636C13.5324 14 13.6943 13.933 13.8136 13.8136C13.933 13.6943 14 13.5324 14 13.3636V9.54545C14 9.37668 13.933 9.21482 13.8136 9.09548C13.6943 8.97614 13.5324 8.90909 13.3636 8.90909ZM3.81818 10.1818V12.7273H1.27273V10.1818H3.81818ZM5.72727 3.81818V1.27273H8.27273V3.81818H5.72727ZM12.7273 12.7273H10.1818V10.1818H12.7273V12.7273Z" />
    </Icon>
  );
};