import React, { ChangeEvent } from "react";
import { Row } from "@tanstack/react-table";
import { useFormContext } from "react-hook-form";
import { FormControl } from "@chakra-ui/react";
import { calcCount, hashCode, replaceComma } from "shared/lib";
import { CombinedOverride, VolumeControl } from "features/doc";

interface IProps {
  row: Row<CombinedOverride>;
}

export const VolumeCell = ({ row }: IProps) => {
  const { setValue } = useFormContext();

  const volumeFieldName = row.original.product ? `1.${hashCode(row.original.product)}.volume` : null;
  const temp_countFieldName = row.original.product ? `1.${hashCode(row.original.product)}.temp_count` : null;

  return (
    <FormControl>
      {volumeFieldName && temp_countFieldName &&
       <VolumeControl
         name={volumeFieldName}
         connectedName={temp_countFieldName}
         onChange={(e: ChangeEvent<HTMLInputElement>) => {
           if (!row.original.product) return;
           if (!row.original.quantity) return;

           const value = replaceComma(e.target.value)
           const temp_count = calcCount({ quantity: +row.original.quantity, volume: +value, })

           setValue(volumeFieldName, value);
           setValue(temp_countFieldName, isNaN(temp_count) ? "" : temp_count);
         }}
       />
      }
    </FormControl>
  )
};