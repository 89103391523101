import { Box } from "@chakra-ui/react";

import { useAppSelector } from "shared/model";
import { useCheques } from "features/doc";
import { ChequeFilter, ChequesTable, DocHeader, DocList } from "widgets/doc";

export function ChequesList() {
  const company = useAppSelector(state => state.companies.current);

  if (!company) {
    return <Box>Компания не выбрана</Box>;
  }

  return (
    <DocList
      useData={useCheques}
      // action={ChequesActions}
      head={<DocHeader label={"Чеки"}><ChequeFilter /></DocHeader>}
      body={<ChequesTable />}
      linkToMap={"/documents/chequesMap"}
    />
  );
}