import { XmlParser } from "shared/lib";

//module
import { IDetails } from "../types/document";

export function makeDocument(xml?: string): IDetails | undefined {
  if (!xml) {
    return;
  }

  const parser = new XmlParser(xml);

  return {
    number: parser.getElementValueByPath("//utd:number"),
    date: parser.getElementValueByPath("//utd:created"),
    seller: parser.getElementValueByPath("//utd:seller/*/ops:name"),
    sum: parser.getElementValueByPath("//utd:invoice/utd:sum"),//сумма с НДС
    shipper: parser.getElementValueByPath("//utd:shipper/*/ops:name"),
    buyer: parser.getElementValueByPath("//utd:buyer/*/ops:name"),
    buyerTin: parser.getElementValueByPath("//utd:buyer/*/ops:tin"),
    consignee: parser.getElementValueByPath("//utd:consignee/*/ops:name"),
    consigneeTin: parser.getElementValueByPath("//utd:consignee/*/ops:tin"),
    consigneeAddress: parser.getElementValueByPath("//utd:consignee/ops:address"),
    positions: parser.getElementsByPath("//utd:position").map((x) => ({
      number: parser.getElementValueByPath("utd:order", x),
      product: parser.getElementValueByPath("utd:name", x),
      price: parser.getElementValueByPath("utd:price", x),
      sum: parser.getElementValueByPath("utd:sum", x),//сумма с НДС
      tax: parser.getElementValueByPath("utd:tax", x),
      quantity: parser.getElementValueByPath("utd:quantity", x),
      measure: parser.getElementValueByPath("utd:measure", x),
    })),
  };
}