import { useCallback } from "react";
import { useUploadDocumentsMutation } from "api";
import { useToast } from "@chakra-ui/react";

export function useUploadDocuments() {
  const [uploadDocuments, uploadDocumentsHelpers] = useUploadDocumentsMutation();
  const toast = useToast();

  const upload = useCallback(
    async (source: string, documents: string[], productsStock: boolean ) => {
      try {
        await uploadDocuments({ source, documents: documents, productsStock }).unwrap()
        toast({
          description: "Документы выгружены",
          status: "success",
          isClosable: true,
        });
      } catch (e) {
        console.error("ошибка выгрузки документов", e)
      }
    },
    [toast, uploadDocuments]
  );

  return {
    upload,
    uploadDocumentsHelpers
  }
}