import { useMemo } from "react";
import _ from "lodash";
import { ColumnDef, getCoreRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable, } from "@tanstack/react-table";

import { IDocument } from "api";
import { useCheques, useDocuments } from "features/doc";

interface IProps {
  columns?: ColumnDef<IDocument>[];
  useData: typeof useDocuments | typeof useCheques;
}

export function useDataTable(
  {
    columns = [],
    useData,
  }: IProps) {
  const documents = useData();

  const sorting = useMemo<SortingState>(() => {
    return !_.isEmpty(documents.sort.field)
      ? [{ id: documents.sort.field!, desc: documents.sort.mode === "desc" }]
      : [];
  }, [documents.sort]);
  const rowSelection = useMemo(
    () => documents.selection, //.reduce((a, c) => ({ ...a, [c.id]: true }), {}),
    [documents.selection]
  );

  const table = useReactTable({
    columns,
    data: documents?.documents?.data || [],
    enableRowSelection: true,
    manualPagination: true,
    manualSorting: true,
    pageCount: Math.ceil((documents?.documents?.total || 0) / documents.pager.pageSize),
    state: {
      pagination: documents.pager,
      rowSelection,
      sorting,
    },
    defaultColumn: {
      minSize: 50,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: (e) => {
      if (_.isFunction(e)) {
        const items = e(sorting);
        if (!_.isEmpty(items)) {
          documents.setSort(items[0].id, items[0].desc ? "desc" : "asc");
        } else {
          documents.setSort(
            documents.sort.field,
            documents.sort.mode === "asc" ? "desc" : "asc"
          );
        }
      }
    },
    onRowSelectionChange: (e) => {
      if (_.isFunction(e)) {
        documents.setSelection(Object.keys(e(rowSelection)));
      }
    },
    onPaginationChange: (e) => {
      if (_.isFunction(e)) {
        documents.setPager(e(documents.pager));
      }
    },
    getRowId: (row, _, parent) => {
      return parent ? [parent.id, row.id].join("_") : row.id;
    },
  });

  return table;
}