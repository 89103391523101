import { createAttachedSignature } from "crypto-pro";
import { useToast } from "@chakra-ui/react";

import { useAddSourceMutation, useFetchDocumentsMutation, useFlightMutation, usePreflightMutation, useUpdateSourceMutation } from "api";
import { ISource, useGetSources } from "entities/sources";
//module
import { useFetchSource } from "../../fetchSourceData/model/useFetchSource";
import { ISourceInputs } from "./source_inputs";

export const useCreateUpdateSourceEds = ({ source }: { source?: ISource }) => {
  const toast = useToast();
  const [addSource, addSourceHelpers] = useAddSourceMutation();
  const [updateSource, updateSourceHelpers] = useUpdateSourceMutation();
  const [, fetchDocumentsHelpers] = useFetchDocumentsMutation();
  const { refetch: getSources } = useGetSources();

  const { fetchSource } = useFetchSource({ source });

  const [postPreflight, postPreflightHelpers] = usePreflightMutation();
  const [postFlight, postFlightHelpers] = useFlightMutation();

  const createUpdateSourceEds = async (formData: ISourceInputs, shouldFetchSource: boolean = true) => {

    if (!formData.EDS_thumbprint) {
      toast({
        description: "Не выбран сертификат",
        status: "warning",
        isClosable: true,
      });
      return false;
    }

    const create = async () => {
      const res = await addSource(formData).unwrap()
      toast({
        description: "Источник успешно создан",
        status: "success",
        isClosable: true,
      });
      return res;
    }

    const update = async () => {
      const res = await updateSource(formData).unwrap();
      toast({
        description: "Источник успешно обновлен",
        status: "success",
        isClosable: true,
      });
      return res;
    }

    const certAuth = async ({ sourceId }: { sourceId: string, body?: string }) => {
      if (!formData.EDS_thumbprint) return false;
      try {
        const res = await postPreflight({ sourceId }) as { data: { data: string } };
        const token = res?.data?.data;
        const signature = await createAttachedSignature(formData.EDS_thumbprint, token);
        return await postFlight({ sourceId, body: signature })
      } catch (e) {
        const error = e as { message?: string }
        toast({
          description: error.message,
          status: "error",
          isClosable: true,
        })
        throw e
      }
    }

    try {
      const res = source?.id ? await update() : await create();
      const sourceId = res.id;
      await certAuth({ sourceId });
      shouldFetchSource && await fetchSource({ source: res });
      await getSources();

      shouldFetchSource &&
      toast({
        description: "Пожалуйста подождите, синхронизация источника может занять несколько минут",
        status: "info",
        isClosable: true,
      });
      return res;
    } catch (e) {
      //ошибка обрабатывается в api
      console.error("onCreateUpdateSource", e)
    }
  }

  const isLoading =
    addSourceHelpers.isLoading
    || updateSourceHelpers.isLoading
    || fetchDocumentsHelpers.isLoading
    || postPreflightHelpers.isLoading
    || postFlightHelpers.isLoading;

  return {
    isLoading,
    createUpdateSourceEds
  }
};