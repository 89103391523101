import { useCallback, useState } from "react";
import moment from "moment/moment";
import { skipToken } from "@reduxjs/toolkit/query";
import { useFetchDocumentsMutation, useGetSourcesQuery, useGetTasksQuery, useLazyGetTasksQuery } from "api";
import { ISource } from "entities/sources";
import { useAppSelector } from "shared/model";

interface IProps {
  source?: ISource;
}

export const useFetchSource = (
  {
    source
  }: IProps) => {

  const [fetchDocuments, fetchDocumentsHelpers] = useFetchDocumentsMutation();

  const [localSource] = useState(source);

  const [getTasks] = useLazyGetTasksQuery();
  const sourceSyncDict = useAppSelector(state => state.tasks.sourceSyncDict);
  const storeTask = sourceSyncDict[localSource?.id || ""];

  useGetTasksQuery(localSource?.id || skipToken);
  useGetTasksQuery(
    (localSource?.id && storeTask?.status === "Running") ? localSource?.id : skipToken,
    { pollingInterval: 3000 });

  const getIsLoading = () => {
    return storeTask?.status === "Running" || false
  }

  const company = useAppSelector(state => state.companies.current);
  useGetSourcesQuery((!getIsLoading() && company?.id) ? { company: company?.id } : skipToken, { refetchOnMountOrArgChange: true });

  const fetchSource = useCallback(async ({ from, to, source, firstSync}: { from?: string, to?:string, source: ISource, firstSync?:  boolean}) => {
    try {
      let range;
      if (from) range = { from, to, firstSync }
      await fetchDocuments(range ? { source, range } : { source }).unwrap();
      await getTasks(source?.id)
    } catch (e) {
      console.warn("onFetch", e)
    }
  }, [fetchDocuments, getTasks]);


  return {
    isLoading: getIsLoading(),
    fetchDocumentsHelpers,
    fetchSource,
    getTasks,
  }
}