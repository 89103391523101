import { useMemo } from "react";
import { ISource } from "entities/sources";
import { OverrideSelector } from "entities/entity";
import { IOverride } from "api";

import { CTable } from "shared/ui";
import { trimmedString } from "shared/lib";

import { ColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

export function SuppliersOverrides(
  {
    data,
    target,
    onChanged,
  }: {
    data: IOverride[];
    target: ISource;
    onChanged?: (e: IOverride) => void;
  }) {
  const columns = useMemo(
    (): ColumnDef<IOverride>[] => [
      {
        id: "original",
        accessorKey: "original",
        header: () => "Поставщик",
        size: 500,
      },
      {
        id: "value",
        accessorKey: "value",
        enableSorting: false,
        size: 500,
        header: () => `Поставщик (${ trimmedString(target.name, 50)})`,
        cell: ({ row }) => (
          <OverrideSelector
            allowNull={true}
            isRequired
            type={2}
            source={target}
            value={row.getValue("value")}
            onChange={(e) => onChanged?.({ ...row.original, value: e })}
          />
        ),
      },
    ],
    [target, onChanged]
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return <CTable {...{ table }}  />;
}