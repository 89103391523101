import { FormControl, FormLabel, HStack, Input, InputGroup, Text } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { floatPattern } from "shared/lib";

export const SumControl = () => {
  const { register, getValues, formState: { errors } } = useFormContext();
  const isError: boolean = !!(errors.fromTotal || errors.toTotal)
  return (
    <FormControl>
      <FormLabel> <Text variant={"form_input_label"}> Сумма, ₽ </Text> </FormLabel>
      <HStack gap={0}
              borderWidth={"1px"}
              borderColor={isError ? "error" : "inherit"}
              borderRadius={"5px"}
              boxSizing={"border-box"}
              h={"30px"}
              backgroundColor={"primary_bg"}
      >
        <InputGroup>
          <Text position={"absolute"} left={"10px"} top={"50%"} transform={"translateY(-55%)"} fontSize={"sm"}>
            от
          </Text>
          <Input
            variant={"primary"}
            _focus={{ borderColor: isError ? "rgb(255, 255, 255, 0)" : "primary_btn", }}
            backgroundColor={"rgba(255,255,255,0)"}
            borderColor={"rgba(255,255,255,0)"}
            borderRightWidth={"1px"}
            borderRightColor={"inherit"}
            borderRadius={"5px 0 0 5px"}
            pl={"30px"}
            {...register("fromTotal", {
              pattern: floatPattern,
              min: 0,
              max: getValues("toTotal") || Number.MAX_SAFE_INTEGER,
              maxLength: 15
            })}
          />
        </InputGroup>

        <InputGroup>
          <Text position={"absolute"} left={"10px"} top={"50%"} transform={"translateY(-55%)"} fontSize={"sm"}>
            до
          </Text>
          <Input
            variant={"primary"}
            _focus={{ borderColor: isError ? "rgb(255, 255, 255, 0)" : "primary_btn", }}
            backgroundColor={"rgba(255,255,255,0)"}
            borderColor={"rgba(255,255,255,0)"}
            borderRightWidth={"1px"}
            borderRadius={"0 5px 5px 0"}
            pl={"30px"}
            {...register("toTotal", {
              pattern: floatPattern,
              min: getValues("fromTotal") || 0,
              max: Number.MAX_SAFE_INTEGER,
              maxLength: 15
            })}
          />
        </InputGroup>
      </HStack>
    </FormControl>
  );
};