import React from "react";
import { Button } from "@chakra-ui/react";
import { useAppSelector } from "shared/model";

//module
import { useUploadDocumentsFeature } from "../model/useUploadDocumentsFeature";

export const UploadButton = () => {
  const sourceId = useAppSelector(state => state.sources.current);
  const {doUpload, isLoading} = useUploadDocumentsFeature();
  return (
    <Button
      isLoading={isLoading}
      colorScheme="blue"
      isDisabled={!sourceId}
      onClick={doUpload}
    >
      Выгрузить
    </Button>
  );
};