import React from "react";

import { CustomInput } from "shared/ui";
import { useCheques } from "features/doc";

//module
import { FilterWrapper } from "../filter/filter_wrapper";
import { DateControl } from "../filter/controls/dateControl";
import { SumControl } from "../filter/controls/sumControl";
import { IsUploadControl } from "../filter/controls/isUploadControl";
import { PersonalFilterControl } from "../filter/controls/personalFilterControl";

export function ChequeFilter() {
  return (
    <FilterWrapper useData={useCheques}>
      <CustomInput
        isNoRightElement={true}
        isNoErrorMessage={true}
        name={"searchNumber"}
        label={"Номер чека"}
        registerOptions={{maxLength: 255}}
      />
      <DateControl />
      <CustomInput
        isNoRightElement={true}
        isNoErrorMessage={true}
        name={"searchSeller"}
        label={"Поставщик"}
        registerOptions={{maxLength: 511}}
      />
      <SumControl />
      <IsUploadControl />
      <PersonalFilterControl />
    </FilterWrapper>
  );
}