import { useMemo } from "react";
import { useAppSelector } from "shared/model";
import { useGetDocumentStatusQuery } from "api";

//module
import { IDocumentUpload } from "./type";

export const useDocumentStatus = ({ id }: {id: string}) => {
  const sourceId = useAppSelector((x) => x.sources.current);
  //получаем статус данного документа
  const { data: statuses, refetch, isLoading } = useGetDocumentStatusQuery({ id });
  // Статус документа для указанного источника
  const status = useMemo<IDocumentUpload | undefined>(() => {
    return statuses?.uploads?.find(item => item?.source === sourceId)
  }, [sourceId, statuses]);

  return {
    status,
    refetch,
    isLoading,
    statuses
  }
};