import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CustomInput } from "shared/ui";

interface IProps {
  name: string;
  connectedName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const TempCountControl = (
  {
    name,
    connectedName,
    onChange,
  }: IProps) => {

  const { trigger } = useFormContext();

  return (
    <CustomInput
      name={name}
      registerOptions={{
        required: { message: "Обязательно к заполнения", value: true },
        validate: (value) => {
          if (value === 0) return "Поле не может быть нулевым"
          if (isNaN(+value)) return "Введите число"
        },
        min: { value: 0, message: "Значение должно быть положительным" },
        max: { value: 999_999, message: "Значение должно быть не более 999 999" },
        valueAsNumber: true,
        onChange,
      }}
      //обновляем валидашку на связанном инпуте
      onBlur={() => {
        trigger(name)
        if (connectedName) trigger(connectedName)
      }}
      isNoRightElement={true}
      hasErrorIcon={true}
    />
  )
};