import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "shared/model";

import { CompaniesActions } from "entities/companies";
import { ICompany } from "entities/companies";

import { useAddCompanyMutation } from "api";
import { Button, Text, VStack } from "@chakra-ui/react";

import { CompanyInputGroup } from "features/companies";
import { ICompanyInputs } from "features/companies";

interface IProps {
  label: string,
  counter?: number,
  text?: string;
  nextLink: string;
  nextStep: () => void;
}

export default function Company({ label, text, nextStep }: IProps) {
  const CompanyForm = useForm<ICompanyInputs>();
  const { handleSubmit } = CompanyForm;
  const dispatch = useAppDispatch();
  const [addCompany, { isLoading }] = useAddCompanyMutation();
  const onSubmit: SubmitHandler<ICompanyInputs> = (formData) => {
    addCompany({ ...formData, tin: " " })
      .then((res) => {
        const resp = res as { data: ICompany }
        if (!resp?.data) return
        dispatch(CompaniesActions.setCurrent(resp?.data));
        nextStep();
      })
  }

  return (
    <>
      <FormProvider {...CompanyForm}>
        <form style={{ display: "contents" }} onSubmit={handleSubmit(onSubmit)}>
          <Text pb={"15px"} variant={"heading"}> {label} </Text>

          {text && <Text pb={"25px"} variant={"descriptor"}>{text}</Text>}

          <VStack gap={"20px"}>
            <CompanyInputGroup isDisabled={isLoading} />

            {/*<CustomInput*/}
            {/*  isDisabled={isLoading}*/}
            {/*  name={"name"}*/}
            {/*  label={"Название Вашей компании (ООО, ИП, ФИО)"}*/}
            {/*  registerOptions={{ required: "Обязательное поле", maxLength: { value: 250, message: "Не более 250 символов" } }}*/}
            {/*/>*/}

            {/*<CustomInput*/}
            {/*  isDisabled={isLoading}*/}
            {/*  name={"tin"}*/}
            {/*  label={"ИНН"}*/}
            {/*  registerOptions={{ required: "Обязательное поле", maxLength: { value: 10, message: "Не более 10 символов" } }}*/}
            {/*/>*/}

            <Button isLoading={isLoading} w={"full"} h={"60px"} type={"submit"} fontSize={"md"}>
              Продолжить
            </Button>
          </VStack>
        </form>
      </FormProvider>
    </>
  );
}
