import React from "react";
import { Props, Select } from "chakra-react-select";
import { useController, UseControllerProps } from "react-hook-form";

import { FormValues } from "./types";

type ControlledSelectProps = UseControllerProps<FormValues> & Props & { hui?: string; }
export const ControlledSelect = (
  {
    control,
    name,
    rules,
    ...props
  }: ControlledSelectProps) => {

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController<FormValues>({
    name,
    control,
    rules
  });

  return (
    <>
      <Select
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
      />
    </>
  );
};