import Example from "./components/test";

export function TestPage() {
  return (
    <Example/>
  )
}






