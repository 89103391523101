import React from "react";
import { RHF_EntitySelector } from "entities/entity";
import { useFormContext } from "react-hook-form";
import { OptionBase } from "chakra-react-select";
import { useStockContext } from "../model/stockContextProvider";

interface IProps {
  name: string;   //={`4.[${hashCode(stock.original)}].value`}
  source: string; //={sourceId}
  type: number;   //={4}
  // localForm: ReturnType<typeof useForm>;
}

interface IEntityOption extends OptionBase {
  label: string;
  value?: string;
  type_id?:
    "DISH" |
    "GOODS" |
    "MODIFIER" |
    "PREPARED" |
    "SERVICE" |
    string;
  type_name?: string;
  num?: string;
}

export const AutoFillStockSelector = (
  {
    name,
    type,
    source,
  }: IProps) => {

  const { setValue, getValues } = useFormContext();
  const { localForm } = useStockContext()
  const onChangeHandler = (val: IEntityOption | null) => {
    // console.log(getValues("1"));
    const productFields = getValues("1");
    const option = val as IEntityOption | null;

    for (const hash in productFields) {
      setValue(`[1].[${hash}].stock`, option?.value || null);
    }

    localForm.setValue(name, option?.value || null);
  }

  // watch(name)

  return (
    <RHF_EntitySelector
      Form={localForm}
      onChange={onChangeHandler}
      name={name}
      isHideArrow={true}
      source={source}
      type={type}
      placeholder={"Не указан"}
      allowNull
      isNonRoundedLeft={true}
    />
  )
}