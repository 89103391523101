import React from "react";

import { BubbleSource } from "./bubble_source";
import { HelpOverlay } from "../help/help_overlay";

interface IProps {
  nextStep: () => void;
}

export const SourceHelp = (
  {
    nextStep
  }: IProps) => {

  return (
    <HelpOverlay nextStep={nextStep}>
      <BubbleSource/>
    </HelpOverlay>
  );
};