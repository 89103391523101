import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useGetSources } from "entities/sources";
import { isEmpty } from "lodash";
import { useAppSelector } from "shared/model";
import { Aggregation } from "./aggregation";

const AggregationBanner = () => {
  const hideBannerFlag = useAppSelector(
    (state) => state.aggregation_banner.hideBannerFlag
  );

  const { isOpen, onToggle } = useDisclosure();

  const { data } = useGetSources();

  const notAggregatedSources = data?.filter(
    ({ aggregation, type }) => aggregation === null && type === 2
  );

  if (isEmpty(notAggregatedSources)) return <></>;
  if (hideBannerFlag) return <></>;

  return (
    <Modal isOpen={!isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>На данных источниках обязателен выбор ящика</ModalHeader>
        <ModalBody>
          <VStack gap={6}>
            {notAggregatedSources?.map((source) => (
              <VStack alignItems={"start"} key={source.id} w={"full"}>
                <Text variant={"descriptor"}>
                  {source?.name} {source?.aggregation}
                </Text>
                <Aggregation source={source} />
              </VStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { AggregationBanner };
