import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { Box, HStack, IconButton } from "@chakra-ui/react";
import { ColumnDef, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { DeleteIcon } from "@chakra-ui/icons";
import { useGetMembersQuery } from "api";

import { useMembersContext } from "features/members";
import { IMember } from "entities/members";
import { AiOutlineEdit } from "react-icons/ai";

export function useMemberTable() {
  const { companyId } = useParams();
  const { data: members, isLoading } = useGetMembersQuery(companyId ?? skipToken);
  const { onOpenMemberModal, onOpenDeleteModal } = useMembersContext();

  //тип, название, дата обновления, состояние
  const columns = useMemo(
    (): ColumnDef<IMember>[] => [
      //   email?: string,
      //   name?: string,
      //   phone?: string,
      //   position?: string,
      //   memberType?: string
      {
        id: "email",
        accessorKey: "email",
        header: "E-mail",
        size: 500,
      },
      {
        id: "name", accessorKey: "name",
        header: "ФИО",
        size: 500,
      },
      {
        id: "phone",
        accessorKey: "phone",
        header: "Номер телефона",
        size: 500,
      },
      {
        id: "position",
        accessorKey: "position",
        header: "Должность",
        size: 500,
      },
      {
        id: "memberType",
        accessorKey: "memberType",
        header: "Тип",
        size: 500,
      },
      {
        id: "controls",
        accessorKey: "controls",
        header: "  ",
        size: 50,
        enableSorting: false,
        cell: (cell) => {
          return (
            <Box>
              <HStack justifyContent={"end"} gap={4}>
                {cell.row.original.memberType !== "Владелец" ?
                  <>
                    <IconButton
                      variant={"outline"} aria-label={"change"}
                      icon={<AiOutlineEdit/>}
                      onClick={() => onOpenMemberModal?.(cell.row.original)}
                    />
                    <IconButton
                      variant={"outline_error"} aria-label={"add source"}
                      icon={<DeleteIcon />}
                      onClick={() => onOpenDeleteModal?.(cell.row.original)}
                    />
                  </>
                  :
                  <IconButton aria-label={"empty"} opacity={0} cursor={"default"} />
                }
              </HStack>
            </Box>
          );
        },
      },
    ],
    [onOpenDeleteModal, onOpenMemberModal],
  );

  const table = useReactTable({
    columns,
    data: members || [],
    enableRowSelection: true,
    manualPagination: true,
    defaultColumn: {
      minSize: 50, //enforced during column resizing
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // getRowId: (row, _, parent) => {
    //   return parent ? [parent.id, row.id].join("_") : row.id;
    // },
  });

  return {
    table,
    isLoading,
  }
}