import React, { ReactNode } from "react";
import { Box, Collapse, HStack, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { CompanyMenuButton } from "features/companies";

//module

interface IProps {
  label: string;
  children: ReactNode;
}

export const DocHeader = ({ label, children }: IProps) => {
  const filterDisclosure = useDisclosure();
  return (
    <Box w={"full"} p={"20px 20px 15px 20px"}>
      <HStack alignItems={"end"} gap={5}>
        <Text variant={"heading"}>{label}</Text>

        <Box onClick={filterDisclosure.onToggle} cursor={"pointer"}>
          <Box display={"inline"}>
            <Text fontSize={"sm"} color={"a"} lineHeight={"1.35"}>
              {!filterDisclosure.isOpen ?
                <>Свернуть фильтр <ChevronUpIcon /></>
                :
                <>Развернуть фильтр<ChevronDownIcon /></>
              }
            </Text>
          </Box>
        </Box>

        <Spacer />

        <CompanyMenuButton />
      </HStack>

      <Collapse in={!filterDisclosure.isOpen}>
        <Box pt={4}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};