import { FormControl, FormLabel, InputGroup, Select, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

const isUploadDefault = (value: boolean | null) => {
  switch (value) {
    case null:
      return 0
    case false:
      return 1
    case true:
      return 2
  }
}

const isUploadSelectOption: (boolean | null)[] = [null, false, true];

export const IsUploadControl = () => {
  const {getValues} = useFormContext();
  return (
    <FormControl>
      <FormLabel> <Text variant={"form_input_label"}> Статус </Text> </FormLabel>
      <InputGroup>
        <Controller
          name={"isUpload"}
          render={({ field }) =>
            <Select
              value={isUploadDefault(getValues("isUpload"))}
              variant={"primary"}
              onChange={(e) => field.onChange(isUploadSelectOption[+e.target.value])}
            >
              <option value={0}>все</option>
              <option value={1}>не выгружено</option>
              <option value={2}>выгружено</option>
            </Select>}
        />
      </InputGroup>
    </FormControl>
  );
};