import { createSlice } from "@reduxjs/toolkit";
import { api } from "api";
import { ITasksState } from "./types";

const initialState: ITasksState = {
  sourceSyncDict: {},
};

const slice = createSlice({
  initialState,
  name: "tasks",
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api.endpoints.getTasks.matchFulfilled,
        (state, { payload, meta }) => {
          const sourceId = meta.arg.originalArgs;
          if (payload.length > 1) console.warn("getTasks", "ожидалась одна таска", payload);
          state.sourceSyncDict[sourceId] = payload.length !== 0 ? { ...payload?.[0], sourceId: sourceId } : null;
        });
  },
});

export const actions = { ...slice.actions };
export const { reducer } = slice;