import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import { useGetDocumentsOverridesQuery } from "api";
import { calcCount, hashCode } from "shared/lib";
import { useAppSelector } from "shared/model";
import { useDetailsContent } from "entities/details";

//module
import { IOverrideDict, IOverrideField } from "../../model/detailsContextProvider";

export function useDetailsForm({ documentId }: { documentId: string }) {
  const sourceId = useAppSelector((x) => x.sources.current);
  const id = documentId;

  //при изменении sourceId перезапрашиваем overrides
  //id не меняется
  const { data: overrides, isLoading: isLoadingDocumentOverrides } = useGetDocumentsOverridesQuery(sourceId && id ? {
      documents: [id],
      target: sourceId,
    } : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );

  //контент документа
  const { document, isLoading: isLoadingDocument } = useDetailsContent({ id });

  const isFormDataLoading = isLoadingDocumentOverrides || isLoadingDocument;

  //нельзя кешировать оверрайды данные, в разных документах они пересекаются
  //закешированные данные будут показывать неактуальные неправильные значения
  const getFormData: () => Promise<IOverrideDict> = useCallback(async () => new Promise((resolve) => {
    const positions = document?.positions;//1
    // const sellerName = details?.seller;//2
    // const stockName = document?.consignee || document?.buyer;//4

    //   //     Counterparty,    2 контрагент
    //   //     Measure,         3 ед. изм.
    //   //     Stock,           4 склад
    //   //     Container        5 упаковка

    const fields: IOverrideDict = {};

    overrides?.forEach(item => {
      if (!fields[item.type]) fields[item.type] = {};
      const findPos = positions?.find(pos => pos.product === item.original);

      const field: IOverrideField = fields[item.type][hashCode(item.original)] = {
        ...item,
        temp_count: null,
      };

      //для товара делаем автозаполнение полей
      if (field.type === 1) {
        if (findPos?.quantity) field.temp_count = calcCount({ quantity: findPos?.quantity, volume: (item?.volume || 1) })
        if (!item?.volume) field.volume = 1;
      }

      //возможно следует добавить поле changedByInit: boolean
      //чтобы отделять поля которые нужно сохранить
    });

    resolve(fields);
  }), [document?.positions, overrides])

  const Form = useForm({ defaultValues: getFormData });

  useLayoutEffect(() => {
    if (sourceId) {
      getFormData().then(res => Form.reset(res))
    }
  }, [Form, getFormData, sourceId])

  return {
    form: Form,
    isLoading: isFormDataLoading,
  }
}