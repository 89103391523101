import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { IDocument } from "api";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Box, Center } from "@chakra-ui/react";

import { CellUploads, CTable, IndeterminateCheckbox, Lookup, Pager, SimpleWrapper, WithLoader } from "shared/ui";

import { useAppSelector } from "shared/model";
import { FormatterUtils } from "shared/lib";

import { useGetSources } from "entities/sources";
import { useDataTable, useDocuments } from "features/doc";

export function DocumentsTable() {
  const nav = useNavigate();
  const { isFetching, isLoadingDocumentStatuses, statusOverrides, ...documents } = useDocuments();

  const { data: sources } = useGetSources();
  const currentSourceId = useAppSelector((x) => x.sources?.current);

  const columns = useMemo(
    (): ColumnDef<IDocument>[] => [
      {
        id: "select",
        size: 20,
        enableSorting: false,
        header: ({ table }) => (
          <Center w={"full"}>
            <Box w={"20px"}>
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            </Box>
          </Center>
        ),
        cell: ({ row }) => (
          <Center w={"full"} onClick={(e) => e.stopPropagation()}>
            <Box w={"20px"}>
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </Box>
          </Center>
        ),
      },
      {
        accessorKey: "number",
        header: "Номер накладной",
        size: 50
      },
      {
        id: "created",
        // header: i18n.t("date"),
        header: "Дата",
        accessorFn: (row) => moment(row.created).local().format(),
        size: 190,
      },
      {
        accessorKey: "seller",
        // header: i18n.t("seller"),
        header: "Поставщик",
        size: 500,
      },
      {
        accessorKey: "buyer",
        // header: i18n.t("buyer"),
        header: "Покупатель",
        size: 500,
      },
      {
        accessorKey: "sum",
        header: "Сумма c НДС",
        accessorFn: (row) => FormatterUtils.toCurrency(row.sum),
      },
      {
        id: "source",
        accessorKey: "source",
        // header: i18n.t("source"),
        header: "Источник",
        cell: ({ row }) => (
          //выводит поле объекта из массива, путем его перебора
          <Lookup
            value={row.getValue("source")}
            source={sources || []}
            keyExpr={"id"}
            valExpr={"name"}
          />
        ),
      },
      {
        accessorKey: "uploads",
        header: "Статус",
        enableSorting: false,
        size: 70,
        cell: ({ row }) =>
          <CellUploads
            isLoading={isLoadingDocumentStatuses}
            isCompleted={!!statusOverrides?.dict[row.original.id]?.isCompleted}
            isUploads={!!row.original.uploads.find(item => item.source === currentSourceId)}
          />,
      },
    ],
    [currentSourceId, isLoadingDocumentStatuses, sources, statusOverrides?.dict],
  );

  const table = useDataTable({ columns, useData: useDocuments });

  const onRowClick = (row: Row<IDocument>) => nav(`/documents/${row.original.id}`);

  return (
    <>
      <WithLoader
        isLoading={isFetching && table?.options?.data?.length === 0}
        // isLoading={isFetching}
        // isLoading={false}
      >

        <SimpleWrapper
          display={"grid"}
          gridTemplateRows={"1fr"}
          maxH={"full"}
          pt={0}
        >
          <Box overflowY={"auto"}>
            <CTable
              {...{ table, onRowClick }}
              isFooterDisable={true}
              footerAddon={
                <Pager
                  {...{ table }}
                  page={documents.pager.pageIndex}
                  pageSize={documents.pager.pageSize}
                  total={documents?.documents?.total || 0}
                  selectionCount={Object.entries(documents?.selection)?.length}
                  styleProps={{
                    border: "none"
                  }}
                />
              }
            />
          </Box>

        </SimpleWrapper>
      </WithLoader>
    </>
  );
}