import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormControl, FormErrorMessage, FormLabel, HStack, Select, Text } from "@chakra-ui/react";
import { Certificate, getUserCertificates, isValidSystemSetup } from "crypto-pro";

export const EdsSelect = () => {
  const { register } = useFormContext();
  const [isValid, setIsValid] = useState(true);

  const [certs, setCerts] = useState<Certificate[] | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const userCertificates = await getUserCertificates();
        const isValidCert = await isValidSystemSetup();
        setIsValid(isValidCert);
        setCerts(userCertificates);
      } catch (e) {
        console.error("useEffect eds_select", e);
        setIsValid(false);
      }
    })()
  }, [])

  return (
    <>
      <FormControl
        w={"full"}
        isInvalid={!isValid}
      >
        <FormLabel>
          <HStack alignItems={"start"}>
            <Text variant={"form_input_label"}>
              Сертификат
            </Text>
          </HStack>
        </FormLabel>

        <Select
          variant={"md"}
          isDisabled={!isValid}
          {...register("EDS_thumbprint")}
        >
          <option
            key={"cert_"}
            style={{ border: "1px solid white", margin: 8, cursor: "pointer" }}
            value={""}
          >
            Не выбрано
          </option>
          {certs?.map((certificate, i) => (
            <option
              key={"cert_" + i}
              style={{ border: "1px solid white", margin: 8, cursor: "pointer" }}
              value={certificate.thumbprint}
            >
              {certificate.name}
            </option>
          ))}
        </Select>

        <FormErrorMessage
          top={"17px"}
        >
          Не найдены установленные сертификаты
        </FormErrorMessage>

      </FormControl>
    </>
  );
};
