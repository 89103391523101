import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { Row } from "@tanstack/react-table";
import { InputGroup } from "@chakra-ui/react";

import { calcVolume, hashCode, replaceComma } from "shared/lib";
import { CombinedOverride, TempCountControl } from "features/doc";

interface IProps {
  row: Row<CombinedOverride>;
}

export const TempCountCell = ({ row }: IProps) => {
  const { setValue } = useFormContext();
  const hash = row.original.product ? hashCode(row.original.product) : "";

  const volumeFieldName = row.original.product ? `[1].[${hash}].volume` : null;
  const temp_countFieldName = row.original.product ? `[1].[${hash}].temp_count` : null;

  return (
    <>
      {volumeFieldName && temp_countFieldName &&
        <InputGroup>
          <TempCountControl
            name={temp_countFieldName}
            connectedName={volumeFieldName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (!row.original.product) return;
              if (!row.original.quantity) return;

              const value = replaceComma(e.target.value)
              const volume = calcVolume({ quantity: +row.original.quantity, count: +value, })

              setValue(temp_countFieldName, value);
              setValue(volumeFieldName, isNaN(volume) ? "" : volume);
            }} />
        </InputGroup>
      }
    </>
  )
};