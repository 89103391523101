import { Center, FormControl, FormLabel, InputGroup, Switch, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

export const PersonalFilterControl = () => {
  const { control } = useFormContext();
  return (
    <FormControl minH={"40px"}>
      <FormLabel> <Text variant={"form_input_label"}> </Text> </FormLabel>
      <Center h={"full"}>
        <InputGroup>
          <Center pr={2}>
            <Controller
              control={control}
              name={"personalFilter"}
              render={({ field: { onChange, value } }) =>
                <Switch
                  isChecked={value}
                  onChange={onChange}
                />
              }
            />
          </Center>
          <Text> Фильтр по списку покупателей </Text>
        </InputGroup>
      </Center>
    </FormControl>
  );
};