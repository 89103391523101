import { useGetEntitiesQuery } from "api";
import { ISource } from "entities/sources";
import { CProgress } from "shared/ui";
import { EntitySelector, IEntity } from "entities/entity";

export function OverrideSelector(
  {
    type,
    source,
    value,
    onChange,
    filter,
    allowNull,
    isHideArrow = false,
    // isRequired,
    isAsync,
  }: {
    type: number;
    source: ISource;
    value?: string;
    onChange?: (s?: string) => void;
    filter?: (i: IEntity) => boolean;
    isRequired?: boolean;
    isAsync?: boolean;
    allowNull?: boolean;
    isHideArrow?: boolean;
  }) {
  const { isFetching } = useGetEntitiesQuery({ source: source.id, type });

  return (
    <>
      {isFetching ?
        <CProgress />
        :
        <EntitySelector
          isHideArrow={isHideArrow}
          source={source.id}
          type={type}
          placeholder={"Не сопоставлено"}
          defaultValue={value}
          onChanged={(e) => {
            onChange?.(e?.id);
          }}
          {...{ filter, allowNull, isAsync }}
        />
      }
    </>
  );
}
