import { CreateSliceOptions, PayloadAction } from "@reduxjs/toolkit";
import { IDocument } from "api";
import moment from "moment";
import { IDataFilter, IDataPaginationState, IDataSort, IDataState } from "entities/doc";

export const defaultFilter: IDataFilter = {
  from: moment().subtract(1, "month").format("yyyy-MM-DD"),
  to: moment().format("yyyy-MM-DD"),
  searchNumber: "",
  searchBuyer: "",
  searchSeller: "",
  personalFilter: false,
  toTotal: "",
  fromTotal: "",
  // isUpload: undefined,
  isUpload: null,
  // targetUpload: ""
}

const initialState: IDataState = {
  // list: [],
  selection: {},
  count: 0,
  pager: { pageIndex: 0, pageSize: 50 },
  sort: { mode: "asc" },
  filter: defaultFilter,
  // statusOverrides: {
  //   list: [],
  //   dict: {}
  // }
};

class Reducer<T extends IDataState> {
  createReducer() {
    return {
      reset: () => initialState,
      setFilter: (
        state: T,
        { payload }: PayloadAction<IDataFilter | undefined>
      ) => {
        state.filter = payload ? { ...state.filter, ...payload } : {};
        state.pager = { ...state.pager, pageIndex: 0 };
      },
      setPager: (state: T, { payload }: PayloadAction<IDataPaginationState>) => {
        state.pager = payload;
      },
      setSelection: (state: T, { payload }: PayloadAction<string[]>) => {
        const dict: { [key: string]: boolean } = {}
        payload.forEach(id => dict[id] = true)
        state.selection = dict;
      },
      refreshSelection: (state: T, { payload }: PayloadAction<IDocument[]>) => {
        const refreshedSelection: { [key: string]: boolean } = {}
        Object.entries(state.selection)
          .forEach(([id,]) => {
            const document = payload.find(doc => doc.id === id);
            if (document) refreshedSelection[document.id] = true;
          })
        state.selection = refreshedSelection;
      },
      setCurrent: (state: T, { payload }: PayloadAction<IDocument | undefined>) => {
        state.current = payload;
      },
      setSort: (
        state: IDataState,
        { payload }: PayloadAction<Partial<IDataSort> | undefined>
      ) => {
        state.sort = { ...state.sort, ...payload };
      },
      // clearStatusOverrides: (state: T) => {
      //   state.statusOverrides = initialState.statusOverrides
      // },
    }
  }
}

const reducers = new Reducer().createReducer();

export const documentsSliceOptions: CreateSliceOptions<IDataState, typeof reducers, string> = {
  name: "",
  initialState,
  reducers,
  // extraReducers: (builder) => {
  //   builder
  //     .addMatcher(
  //       api.endpoints.getStatusOverrides.matchFulfilled,
  //       (state, { payload }) => {
  //         state.statusOverrides.list = payload;
  //         payload.forEach(item => state.statusOverrides.dict[item.id] = item)
  //       })
  // },
}