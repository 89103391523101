import React from "react";
import { useFormContext } from "react-hook-form";
import { Row } from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";

import { IDataState } from "shared/model";
import { hashCode } from "shared/lib";
import { IEntity } from "entities/entity";
import { CombinedOverride } from "features/doc";

interface IProps {
  row: Row<CombinedOverride>;
  products: IDataState<IEntity, IEntity>;
}

export const EntityMeasureCell = ({ row, products }: IProps) => {
  const { watch, getValues } = useFormContext();
  const hash = row.original.product ? hashCode(row.original.product) : "";
  const product = products?.dict[getValues(`[1].[${hash}].value`)];
  const measure_name = product?.measure_name ?? "н.у.";

  watch(`[1].[${hash}].value`)
  return (
    <>
      {product?.measure_name &&
        <Text>
          {measure_name}
        </Text>
      }
    </>
  )
};