import React from "react";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetMembersQuery } from "api";
import { ItemNotFound, PageWithHeading, SimpleWrapper } from "shared/ui";
import { MembersProvider } from "features/members";
import { MembersTable } from "widgets/members";

export function MembersPage() {
  const { companyId } = useParams();
  const { data: members, isLoading } = useGetMembersQuery(companyId ?? skipToken);

  if (!members && !isLoading) return <ItemNotFound message={"Не найдена компания"} />;

  return (
    <MembersProvider>
      <PageWithHeading label={"Сотрудники"}>
        <SimpleWrapper pt={0}>
          <MembersTable />
        </SimpleWrapper>
      </PageWithHeading>
    </MembersProvider>
  );
}

