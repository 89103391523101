import React, { ReactNode, useCallback } from "react";
import { Box, Grid } from "@chakra-ui/react";

import { useAppSelector } from "shared/model";
import { ButtonPanelWrapper } from "shared/ui";
import { useDocuments, useCheques } from "features/doc";

//module
import { ButtonPanel } from "./button_panel";

interface IProps {
  useData: typeof useDocuments | typeof useCheques;
  head: ReactNode;
  body: ReactNode;
  linkToMap: string;
  // action: {
  //   setFilter: (payload?: IDataFilter | undefined) => { payload: IDataFilter | undefined; type: string; }
  // }
}

export function DocList({ useData, head, body, linkToMap }: IProps) {
  // const d = useAppDispatch();
  const company = useAppSelector(state => state.companies.current);
  const currentSourceId = useAppSelector((x) => x.sources.current);

  const { selection, upload, uploadDocumentsHelpers, isFetching } = useData();
  const isLoading = isFetching || uploadDocumentsHelpers.isLoading;
  const isDisabled = Object.entries(selection).length === 0 || !currentSourceId;
  // const isDisabled = !currentSourceId;

  // const onChangeSource = useCallback((id?: string) => {
  //   d(SourcesActions.setSelect(id || ""));
  //   d(action.setFilter({ ...filter, targetUpload: id }));
  // }, [action, d, filter]);

  const doUpload = useCallback(async () => {
    if (currentSourceId) await upload(currentSourceId)
  }, [currentSourceId, upload]);

  if (!company) {
    return <Box>Компания не выбрана</Box>;
  }

  return (
    <Grid gridTemplateRows={"auto 1fr auto"} h={"full"}>

      {head}

      <Box overflowY={"auto"}>
        {body}
      </Box>

      <ButtonPanelWrapper>
        <ButtonPanel
          isLoading={isLoading}
          isDisabled={isDisabled}
          // onChangeSource={onChangeSource}
          doUpload={doUpload}
          linkToMap={linkToMap}
        />
      </ButtonPanelWrapper>
    </Grid>
  );
}