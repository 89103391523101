import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetCheckedDepartmentsQuery, useGetDepartmentsQuery } from "api";
import { useAppSelector } from "shared/model";

export function useSellerFilterDataCheck() {
  const { sourceId } = useParams();
  const companyId = useAppSelector(state => state.companies?.current?.id);
  const { data: departments, isLoading: isLoadingDepartments } = useGetDepartmentsQuery(sourceId ?? skipToken);

  const {
    data: checkedDepartments,
    isLoading: isLoadingCheckedDepartments,
  } = useGetCheckedDepartmentsQuery(companyId && sourceId ? {
    companyId,
    sourceId,
  } : skipToken);

  const isLoading = isLoadingDepartments || isLoadingCheckedDepartments;
  const isHasData = !!departments || !!checkedDepartments;

  return {
    isShowNoFound: !isHasData && !isLoading,
  };
}