import React from "react";
import { Button, Container, HStack, useBoolean } from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";

import { FoodGroup, FormValues } from "./types";
import { ControlledSelect } from "./controlledSelect";

const foodGroups: FoodGroup[] = [
  {
    label: "Fruits",
    value: "fruit"
  },
  {
    label: "Vegetables",
    value: "vegetable"
  },
  {
    label: "Grains",
    value: "grain"
  },
  {
    label: "Protein Foods",
    value: "protein"
  },
  {
    label: "Dairy",
    value: "dairy"
  }
];

const defaultValues: FormValues = { food: null };


const Example = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues
  });

  const [isLoading, setLoading] = useBoolean(false);

  const submit: SubmitHandler<FormValues> = async (data) => {
    setLoading.on();
    setTimeout(() => {
      setLoading.off();
      alert(JSON.stringify(data, null, 2));
    }, 3000);
  };

  return (
    <Container as="form" mb={12} onSubmit={handleSubmit(submit)}>

      <ControlledSelect
        control={control}
        name="food"
        options={foodGroups}
        placeholder="Food Groups"
        rules={{ required: "Please select a food group." }}
      />

      <HStack spacing={4}>
        <Button
          isLoading={isLoading}
          type="button"
          colorScheme="blue"
          w="full"
          onClick={() => reset(defaultValues)}
        >
          Reset
        </Button>

        <Button
          isLoading={isLoading}
          type="submit"
          colorScheme="green"
          w="full"
        >
          Submit
        </Button>
      </HStack>
    </Container>
  );
};

export default Example;