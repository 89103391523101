import { useGetDocumentsQuery, useLazyGetDocumentsQuery } from "api";
import { useAppSelector } from "shared/model";
import { DocumentsActions } from "entities/doc";

//module
import { useData } from "../../model/useData";

export function useDocuments() {
  const documentState = useAppSelector(state => state.documents)
  return useData({
    type: 0,
    documentState,
    getDataQuery: useGetDocumentsQuery,
    getLazyDataQuery: useLazyGetDocumentsQuery,
    actions: DocumentsActions,
  });
}