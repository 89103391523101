import { useDocuments } from "features/doc";
import { CustomInput } from "shared/ui";

//module
import { FilterWrapper } from "../filter/filter_wrapper";
import { DateControl } from "../filter/controls/dateControl";
import { SumControl } from "../filter/controls/sumControl";
import { IsUploadControl } from "../filter/controls/isUploadControl";
import { PersonalFilterControl } from "../filter/controls/personalFilterControl";

export function DocumentFilter() {
  return (
    <FilterWrapper useData={useDocuments}>
      <CustomInput
        isNoRightElement={true}
        isNoErrorMessage={true}
        name={"searchNumber"}
        label={"Номер накладной"}
        registerOptions={{maxLength: 255}}
      />
      <DateControl />
      <CustomInput
        isNoRightElement={true}
        isNoErrorMessage={true}
        name={"searchSeller"}
        label={"Поставщик"}
        registerOptions={{maxLength: 600}}
      />
      <CustomInput
        isNoRightElement={true}
        isNoErrorMessage={true}
        name={"searchBuyer"}
        label={"Покупатель"}
        registerOptions={{maxLength: 600}}
      />
      <SumControl />
      <IsUploadControl />
      <PersonalFilterControl />
    </FilterWrapper>
  );
}