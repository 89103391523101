import { CustomInput } from "shared/ui";
import { emailPattern } from "shared/lib";

//   "name": "string",
//   "email": "string",
//   "phone": "string",
//   "position": "string",
//   "memberType": "string"

interface IProps {
  isDisabled?: boolean;
  disableEmailField?: boolean,
}

export default function MemberInputGroup(
  {
    isDisabled = false,
    disableEmailField = false,
  }: IProps) {
  return (
    <>
      <CustomInput
        variant={"md"}
        isDisabled={isDisabled}
        name={"name"}
        label={"ФИО"}
        registerOptions={{ maxLength: { value: 250, message: "Не более 250 символов" } }}
      />

      <CustomInput
        variant={"md"}
        isDisabled={isDisabled || disableEmailField}
        name={"email"}
        label={"E-mail"}
        registerOptions={{
          required: "Обязательное поле",
          maxLength: { value: 250, message: "Не более 250 символов" },
          pattern: emailPattern,
        }}
      />

      <CustomInput
        variant={"md"}
        isDisabled={isDisabled}
        name={"phone"}
        label={"Номер телефона"}
        registerOptions={{ maxLength: { value: 250, message: "Не более 250 символов" } }}
      />

      <CustomInput
        variant={"md"}
        isDisabled={isDisabled}
        name={"position"}
        label={"Должность"}
        registerOptions={{ maxLength: { value: 250, message: "Не более 250 символов" } }}
      />

    </>
  );
}