import { Box, Center, HStack, Spinner, useToast } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetAggregationsQuery, useSetAggregationMutation } from "api";
import { ISource } from "entities/sources";
import { useFetchSource } from "features/sources/fetchSourceData/model/useFetchSource";
import moment from "moment";
import { useForm } from "react-hook-form";
import { AcceptButton, CustomFormProvider, WithLoader } from "shared/ui";
import { AggregationInput } from "./aggregation_input";

interface IProps {
  source: ISource;
  onSuccess?: () => void;
}

interface IAggreagationInputs {
  aggregation: string;
}

const AggregationForm = ({ source, onSuccess }: IProps) => {
  const toast = useToast();
  const { data, isLoading: isLoadingData } = useGetAggregationsQuery(
    source?.type === 2 ? source.id : skipToken
  );
  const [setAggregation, { isLoading }] = useSetAggregationMutation();

  const Form = useForm<IAggreagationInputs>({
    defaultValues: { aggregation: data?.[0]?.id },
  });

  const {
    formState: { isValid },
  } = Form;

  const { fetchSource } = useFetchSource({ source });

  const onSubmitHandler = async (inputs: IAggreagationInputs) => {
    try {
      const selectedAggregation = data?.find(
        (item) => item.id === inputs.aggregation
      );
      selectedAggregation &&
        (await setAggregation({
          sourceId: source?.id,
          aggregation: selectedAggregation,
        }));
      fetchSource({
        source,
        from: moment().subtract(30, "day").format("YYYY-MM-DD"),
        firstSync: true,
      });
      onSuccess?.();
    } catch {
      toast({
        description: "Ошибка привязки ящика",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <Box w={"full"}>
      <CustomFormProvider onSubmit={onSubmitHandler} Form={Form}>
        <WithLoader
          isLoading={isLoadingData}
          loader={
            <Center w={"full"} h={"64px"}>
              <Spinner size={"xl"} color={"primary_btn"} />
            </Center>
          }
        >
          <AggregationInput source={source} label="Ящик" />
        </WithLoader>
        <HStack justifyContent={"end"} w={"full"}>
          <AcceptButton isDisabled={!isValid} isLoading={isLoading}>
            Принять
          </AcceptButton>
        </HStack>
      </CustomFormProvider>
    </Box>
  );
};

export { AggregationForm };
