import React, {ReactNode} from "react";
import {Box, Button, Center, Fade, Grid, HStack, Image, Spacer, VStack} from "@chakra-ui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";

import {ChequesIcon, CompanyIcon, DocumentsIcon, imgLogoSmall, imgLogoTitle, SourceIcon} from "shared/assets";
import {HelpBar} from "features/help";
import {UserMenu} from "features/user";
import {CompanyMenuButton} from "features/companies";

import {FakeNavButton} from "./FakeNavButton";

interface IProps {
  children?: ReactNode;
  nextStep: () => void;
}

export const HelpOverlay = (
  {
    nextStep,
    children,
  }: IProps) => {

  const isOpen = true
  return (
    <>
      <Box
        w={"full"}
        h={"full"}
        position={"fixed"}
        top={0}
        left={0}

        zIndex={1}
      >

        <Grid gridTemplateColumns={"auto 1fr"} height={"full"}>
          <VStack
            bg={"navbar"}
            w={isOpen ? "230px" : "60px"}
            minW={isOpen ? "230px" : "60px"}
            transition={"all 0.3s"}
          >

            <Box w={"full"} position={"relative"}>
              <Box
                w={isOpen ? "230px" : "60px"}
                h={"70px"}
                pl={"1px"}
              >
                <Center justifyContent={"start"} h={"full"} pl={"14px"}>
                  <Image src={imgLogoSmall} h={"40px"}/>
                  <Fade in={isOpen}>
                    <Image src={imgLogoTitle} h={"40px"} pl={"10px"}/>
                  </Fade>
                </Center>

              </Box>
              <Box w={"full"} borderBottom={"1px solid white"}></Box>
              <Center
                backgroundColor={"white"}
                borderRadius={"100%"}
                border={"1px solid"}
                borderColor={"primary_btn"}

                position={"absolute"}
                bottom={0}
                right={0}
                transform={"translate(50%, 50%)"}
                w={6}
                h={6}
                cursor={"pointer"}
                zIndex={15}
              >
                {isOpen ? <ChevronLeftIcon color={"primary_btn"}/> : <ChevronRightIcon color={"primary_btn"}/>}
                <Box p={5} position={"absolute"}></Box>
              </Center>
            </Box>

            <VStack
              w={"full"}
              p={"5px 0"}
              flexGrow={2}
              gap={3}

              alignItems={"start"}
            >
              <FakeNavButton isOpen={isOpen} label={"Накладные"} icon={DocumentsIcon} iconProps={{fill: "white"}}/>
              <FakeNavButton isOpen={isOpen} label={"Чеки"} icon={ChequesIcon} iconProps={{fill: "white"}}/>
              <Spacer/>
              <FakeNavButton isOpen={isOpen} label={"Предприятия"} icon={CompanyIcon}
                             iconProps={{fill: "white", width: "14px"}}/>
              <FakeNavButton isOpen={isOpen} label={"Источники данных"} icon={SourceIcon}
                             iconProps={{fill: "white", width: "14px"}}/>

              <HelpBar isOpen={isOpen}/>
            </VStack>
            <Center
              h={"80px"} w={"full"}
              gap={"5px"}
              alignItems={"center"} borderTop={"1px solid white"}
            >
              <UserMenu>
              </UserMenu>
            </Center>
          </VStack>
          <Box overflowY={"auto"} backgroundColor={"main_bg"}>
            <HStack p={"20px"} justifyContent={"end"}>
              <CompanyMenuButton/>
            </HStack>
          </Box>
        </Grid>

      </Box>


      {/*//overlay*/}
      <Box
        w={"full"}
        h={"full"}
        position={"fixed"}
        top={0}
        left={0}

        zIndex={5}
        backgroundColor={"rgba(0,0,0,0.5)"}
      >
        {children}

        <Box
          position={"absolute"}
          bottom={"120px"}
          left={"50%"}
          transform={"translateX(-50%)"}
        >
          <Button
            onClick={nextStep}
          >
            Далее
            <ChevronRightIcon/>
          </Button>
        </Box>
      </Box>

    </>
  );
};