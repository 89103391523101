import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WizardWrapper from "./WizardWrapper";
import Greetings from "./steps/greetengs";
import Company from "./steps/company";
import Source from "./steps/source";
import { useGetCompaniesQuery, useLazyGetSourcesQuery } from "api";
import { useAppSelector } from "shared/model";

import { WithLoader } from "shared/ui";

//module
import { CompanyHelp } from "./steps/company_help/company_help";
import {SourceHelp} from "./steps/source_help/source_help";

interface IProps {
  setShow: (bool: boolean) => void
}

export const SetupWizard = ({ setShow }: IProps) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const { current } = useAppSelector((store) => store.companies);
  const [getSources, { isLoading }] = useLazyGetSourcesQuery();
  useGetCompaniesQuery();

  useEffect(() => {
    if (current?.id) {
      getSources({ company: current?.id })
        .then((data) => {
          //проверяем, сколько источников, игнорируем ФНС (type 512)
          const count = data?.data?.filter(source => source.type !==  512)?.length
          setStep((count || 0) + 2)
        })
    }
  }, [current, getSources]);

  const stepsArr = [
    <Greetings key={1} nextStep={nextStep} />,
    <Company nextStep={nextStep} key={2}
             label={"Шаг 1"} counter={1}
             text={"Наше приложение позволит Вам обмениваться документами между ЭДО и iiko в пару кликов"}
             nextLink={"../sourceFrom"}
    />,
    <CompanyHelp key={3} nextStep={nextStep} />,
    <Source nextStep={nextStep} key={4}
            label={"Шаг 2"}
            text={"Добавьте источник данных, откуда будем получать документы"}
            direction={"from"}
    />,
    <Source nextStep={nextStep} key={5}
            label={"Шаг 3"}
            text={"Добавьте источник данных, куда будем выгружать документы"}
            direction={"to"}
    />,
    <SourceHelp nextStep={nextStep} key={6}/>,
  ]

  function nextStep() {
    if (!stepsArr?.[step]) navigate("/sources");
    setStep(step + 1)
  }

  useEffect(() => {
    //не будет срабатывать бесконечно, тк setShow(false) размонтирует компонент
    if (!stepsArr?.[step]) {
      setShow(false);
    }
  })

  return (
    <WizardWrapper>
      <WithLoader isLoading={isLoading}>
        <>{stepsArr[step]}</>
      </WithLoader>
    </WizardWrapper>
  );
};