import React from "react";
import { useForm } from "react-hook-form";
import { HStack } from "@chakra-ui/react";

import { AcceptButton, CustomFormProvider } from "shared/ui";
import { IMember } from "entities/members";

//module
import MemberInputGroup from "./MemberInputGroup";
import { IMemberInputs } from "./member_inputs";

interface IProps {
  selectedMember?: IMember;
  companyId?: string;
  onClose: () => void;
  // onSubmit: ({company, member}: {company: ICompany, member: IMember} ) => void;
  onSubmit: (member: IMember) => void;
  isLoading?: boolean;
}

export const MemberForm = (
  {
    selectedMember,
    companyId,
    onSubmit,
    isLoading,
    // onClose,
  }: IProps) => {

  const Form = useForm<IMemberInputs>({ defaultValues: { companyId, ...selectedMember } });

  return (
    <CustomFormProvider onSubmit={onSubmit} Form={Form}>
      <MemberInputGroup
        disableEmailField={!!selectedMember}
        isDisabled={isLoading}
      />
      <HStack justifyContent={"end"} w={"full"}>
        {/*<CancelButton*/}
        {/*  isDisabled={isLoading}*/}
        {/*  onClick={onClose}*/}
        {/*/>*/}
        <AcceptButton
          isLoading={isLoading}
        >
          Сохранить
        </AcceptButton>
      </HStack>
    </CustomFormProvider>
  );
};