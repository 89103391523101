import React from "react";
import { RHF_EntitySelector } from "entities/entity";
import { useStockContext } from "./stock_selector/model/stockContextProvider";

interface IProps {
  name: string;   //={`4.[${hashCode(stock.original)}].value`}
  source: string; //={sourceId}
  type: number;   //={4}
}


export const ProductStockSelector = (
  {
    name,
    type,
    source,
  }: IProps) => {

  // const { setValue, getValues } = useFormContext();
  const { localForm: stockForm } = useStockContext();
  const sideEffect = () => {
    stockForm.reset();
    // const stockFields = getValues("4");
    // for (const hash in stockFields) {
    //   setValue(`[4].[${hash}].value`, null);
    // }
  }

  return (
    <RHF_EntitySelector
      onChangeSideEffectCallback={sideEffect}
      name={name}
      isHideArrow={true}
      source={source}
      type={type}
      placeholder={"Не сопоставлено"}
      allowNull
    />
  )
}