import { FieldValues, useFormContext, Validate } from "react-hook-form";
import { FormControl, FormLabel, Select, Text } from "@chakra-ui/react";

import { CustomInput } from "shared/ui";
import { useGetSources } from "entities/sources";

//module
import { EdsSelect } from "./eds_select";
import { ISourceOption } from "../../model/source_options";
import { appSettings } from "../../../../../shared/lib";

interface IProps {
  direction?: "from" | "to";
  isDisabled?: boolean;
  disabledFields?: { [key: string]: boolean };
}

export function SourceInputGroupEDS(
  {
    isDisabled = false,
    disabledFields
  }: IProps) {

  const { data: sources } = useGetSources();
  const { register, getValues } = useFormContext();

  const sourcesOptions: ISourceOption[] = [
    { value: 3, disabled: false },

    { value: 1, disabled: true },
    { value: 2, disabled: true },
    { value: 512, disabled: true },
    { value: 1024, disabled: true },
  ];

  const validateSourceName: Validate<string, FieldValues> = () => {
    if (!sources) return
    for (const item of sources) {
      if (item.id === getValues("id")) continue;
      if (item.name === getValues("name")) return "Источник с таким именем уже существует";
    }
    return;
  };

  return (
    <>
      {getValues("type") !== 512 &&
        <CustomInput
          variant={"md"}
          isDisabled={!!disabledFields?.["name"] || isDisabled}
          name={"name"} label={"Имя"}
          registerOptions={{
            required: "Обязательное поле",
            maxLength: { value: 255, message: "Максимальная длина 255 символов" },
            validate: validateSourceName,
          }}
        />
      }

      <FormControl w={"full"}>
        <FormLabel> <Text variant={"form_input_label"}> Тип </Text> </FormLabel>
        <Select
          w={"full"}
          isDisabled={!!disabledFields?.["type"] || isDisabled}
          variant={"md"}
          {...register("type", { valueAsNumber: true })}
        >
          {sourcesOptions.map(item =>
            <option key={item.value} value={item.value} disabled={item.disabled}>
              {appSettings?.source?.get(item.value)?.name}
            </option>
          )}
        </Select>
      </FormControl>

      <EdsSelect />
    </>
  );
}