import { useParams } from "react-router-dom";
import { Center } from "@chakra-ui/react";

import { ItemNotFound } from "shared/ui";
import { Details } from "widgets/details";
import { DetailsContextProvider, StockContextProvider } from "features/doc";
import { useDetailsContent } from "entities/details";

export const DetailsPage = () => {
  const { id } = useParams();
  const { document, isLoading } = useDetailsContent({ id });

  if (!document && !isLoading) return <ItemNotFound message={"Документ не найден"} />;

  return id ? (
    <DetailsContextProvider {...{ id }}>
      <StockContextProvider>
        <Details />
      </StockContextProvider>
    </DetailsContextProvider>
  ) : (
    <Center>Не указан идентификатор документа</Center>
  );
}