import { useGetChequesQuery, useLazyGetChequesQuery } from "api";
import { useAppSelector } from "shared/model";
import { ChequesActions } from "entities/doc";

//module
import { useData } from "../../model/useData";

export function useCheques() {
  const documentState = useAppSelector(state => state.cheques)
  return useData({
    type: 128,
    documentState,
    getDataQuery: useGetChequesQuery,
    getLazyDataQuery: useLazyGetChequesQuery,
    actions: ChequesActions,
  });
}