import React from "react";
import { ItemNotFound } from "shared/ui";
import { SourcesFilterTable } from "widgets/sellerFilter";
import { useSellerFilterDataCheck } from "./model/useSellerFilterDataCheck";

export function SourcesFilterPage() {
  const { isShowNoFound } = useSellerFilterDataCheck();

  if (isShowNoFound) return <ItemNotFound message={"Не найдены фильтры "} />;

  return (
    <>
      <SourcesFilterTable />
    </>
  );
}