import ReactDOM from "react-dom/client";
import Application from "app/application";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";
import * as Sentry from "@sentry/react";
import theme from "theme";
import moment from "moment";

import "moment/dist/locale/ru";
import "app/main.scss";

import { Defaults } from "shared/lib";

moment.locale("ru");
moment.defaultFormat = Defaults.DateFormat;

Sentry.init({
  dsn: import.meta.env.APP_SENTRY_DSN,
  environment: import.meta.env.MODE,
  //enabled: !import.meta.env.DEV,
  enabled: true,
  tunnel:
    import.meta.env.MODE == "development"
      ? "http://localhost:5150"
      : window.location.origin + "/api/v1/tunnel",
  tracePropagationTargets: [
    "localhost:5150",
    window.location.origin,
    /^\/api\//,
  ],
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ChakraProvider theme={theme}>
    <ReduxProvider store={store}>
      <PersistGate {...{ persistor }}>
        <Router>
          <Application />
        </Router>
      </PersistGate>
    </ReduxProvider>
  </ChakraProvider>
);
