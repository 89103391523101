import {  createSlice } from "@reduxjs/toolkit";

import { documentsSliceOptions } from "../../data";

const slice = createSlice({
  ...documentsSliceOptions,
  name: "documents",
});

export const actions = { ...slice.actions };
export const { reducer } = slice;
