import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetDocumentQuery } from "api";

//module
import { makeDocument } from "../utils/makeDocument";
import { IDetails } from "../types/document";

interface IUseDetailsContent {
  id?: string;
}

//отдает document details content json
export function useDetailsContent({ id }: IUseDetailsContent) {
  // xml контент данного документа
  const { data: xml, refetch, isLoading } = useGetDocumentQuery(id ? { id } : skipToken);
  // Document information
  const document: IDetails | undefined = useMemo<IDetails | undefined>(
    () => makeDocument(xml),
    [xml],
  );
  return {
    isLoading,
    document,
    refetch,
  };
}