import { IEntity } from "../../model/entity";
import { IEntityOption } from "./type";

export function convertToOption(entity: IEntity): IEntityOption {
  return {
    type_name: entity.type_name,
    type_id: entity.type_id,
    num: entity.num,
    label: entity.name,
    value: entity.id,
  };
}