import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { RootState } from "store";

import type {
  ICompanyCreateModel,
  ICreateMemberModel,
  IDocument,
  IEmailConfirmationModel,
  IGetDocs,
  IOverride,
  IPagedModel,
  IRestoreConfirmationModel,
  ITokenResponse
} from "./models";

import { IDataState, IDict } from "shared/model";

import type { IDataStatusOverride } from "entities/doc";
import type { IDepartment, IProperty, ISource } from "entities/sources";
import type { IUserLoginModel } from "entities/user";
import type { ICompany } from "entities/companies";
import type { IMember } from "entities/members";
import type { ITask } from "entities/tasks";
import type { IDocumentStatus } from "entities/doc_status";
import type { IEntity } from "entities/entity";

//module
import type { IFetchDocumentsCommand } from "./models/documents";
import { IAggregation } from "entities/aggregations";

const baseUrl =
  import.meta.env.MODE == "development"
    // ? "https://trusiki.cl.ru.net"
    ? "https://edi.devnation.ru/"
    // ? "http://localhost:5150"
    : window.location.origin;

export const api = createApi({
  reducerPath: "api",
  tagTypes: [
    "companies",
    "sources",
    "checkedDepartments",
    "departments",
    // "XML_document_details",
    "documentStatus",
    "documentStatusOverride",
    "documents",
    "cheques",

    "members",
    "overrides",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { user } = getState() as RootState;
      if (user.isLogged) {
        headers.set("authorization", `Bearer ${user.token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/member",
        method: "POST",
        body: payload,
      }),
    }),
    login: builder.mutation<ITokenResponse, IUserLoginModel>({
      query: (payload) => ({
        url: "/api/v1/member/token",
        method: "POST",
        body: payload,
      }),
    }),
    confirmEmail: builder.mutation<void, IEmailConfirmationModel>({
      query: (payload) => ({
        url: "/api/v1/member/confirm",
        method: "POST",
        body: payload,
      }),
    }),
    // для сброса пароля есть два метода
    // в первом (GET /Member/reset?email=x) указывается почта, и на нее придет ссылка для сброса пароля.
    // ссылка выглядит вот таким образом
    // https://edi.dedibyte.ru/reset?token=@Model.Token&email=@Model.Email
    //  ты у себя открываешь страницу, можешь для наглядности отрисовать почту где нить и окно для ввода нового пароля
    // и потом все отправляешь на POST /Member/reset
    sendRestorePasswordMail: builder.query<void, { email: string }>({
      query: ({ email }) => ({
        url: `/api/v1/Member/reset?email=${email}`,
      }),
    }),
    confirmRestore: builder.mutation<void, IRestoreConfirmationModel>({
      query: (payload) => ({
        url: "/api/v1/Member/reset",
        method: "POST",
        body: payload,
      }),
    }),
    //members
    // GET
    // /api/v1/companies/{id}/members
    getMembers: builder.query<IMember[], string>({
      query: (companyId) => ({
        url: `/api/v1/companies/${companyId}/members`,
        method: "GET",
      }),
      providesTags: [{ type: "members", id: "LIST" }]
    }),
    // POST
    // /api/v1/companies/{id}/members
    createMember: builder.mutation<IMember, ICreateMemberModel>({
      query: ({ companyId, ...member }) => ({
        url: `/api/v1/companies/${companyId}/members`,
        method: "POST",
        body: member,
      }),
      invalidatesTags: [{ type: "members", id: "LIST" }]
    }),
    // PUT
    // /api/v1/companies/{id}/members
    updateMember: builder.mutation<IMember, ICreateMemberModel>({
      query: ({ companyId, ...member }) => ({
        url: `/api/v1/companies/${companyId}/members`,
        method: "PUT",
        body: member,
      }),
      invalidatesTags: [{ type: "members", id: "LIST" }]
    }),
    // DELETE
    // /api/v1/companies/{id}/members
    deleteMember: builder.mutation<IMember, ICreateMemberModel>({
      query: ({ companyId, ...member }) => ({
        params: member,
        url: `/api/v1/companies/${companyId}/members`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "members", id: "LIST" }]
    }),

    //companies
    getCompanies: builder.query<ICompany[], void>({
      query: () => ({
        url: "/api/v1/companies",
      }),
      providesTags: [{ type: "companies", id: "LIST" }],
    }),
    addCompany: builder.mutation<ICompany, ICompanyCreateModel>({
      query: (payload) => ({
        url: "/api/v1/companies",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "companies", id: "LIST" }],
    }),
    updateCompany: builder.mutation<ICompany, ICompany>({
      query: (payload) => ({
        url: `/api/v1/companies/${payload.id}/update`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "companies", id: "LIST" }],
    }),
    // deleteCompany: builder.mutation<ICompany[], ICompany>({
    //   query: (payload) => ({
    //     url: "/api/v1/companies",
    //     method: "DELETE",
    //     body: payload,
    //   }),
    //   invalidatesTags: [{ type: "companies", id: "LIST" }],
    // }),

    //sources
    getSources: builder.query<ISource[], { company: string }>({
      query: (payload) => ({
        url: `/api/v1/sources?company=${payload.company}`,
      }),
      providesTags: [{ type: "sources", id: "LIST" }],
    }),
    addSource: builder.mutation<ISource, Partial<ISource>>({
      query: (payload) => ({
        url: "/api/v1/sources",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "sources", id: "LIST" }],
    }),
    updateSource: builder.mutation<ISource, Partial<ISource>>({
      query: (payload) => ({
        url: `/api/v1/sources/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [{ type: "sources", id: "LIST" }],
    }),
    deleteSource: builder.mutation<void, ISource>({
      query: (payload) => ({
        url: `/api/v1/sources/${payload.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "sources", id: "LIST" },
        { type: "documents", id: "LIST" }
      ],
    }),

    //получение ящиков (специфика для Диадока)
    //GET {{url}}/v1/Sources/{{sourceId}}/aggregations
    getAggregations: builder.query<IAggregation[], string>({
      query: (sourceId) => ({
        url: `/api/v1/sources/${sourceId}/aggregations`,
        method: "GET",
      }),
    }),
    //выбор ящика
    //POST {{url}}/v1/Sources/{{sourceId}}/aggregations
    setAggregation: builder.mutation<IAggregation, {sourceId: string, aggregation: IAggregation}>({
      query: ({sourceId, aggregation}) => ({
        url: `/api/v1/sources/${sourceId}/aggregations`,
        method: "POST",
        body: aggregation
      }),
      invalidatesTags: ["sources"]
    }),

    //EDS
    //авторизация источника по электронной подписи

    //запрос, чтобы получить токен от API
    preflight: builder.mutation<{ data: string }, { sourceId: string, body?: string }>({
      query: ({ sourceId }) => ({
        url: `/api/v1/Sources/${sourceId}/Preflight`,
        method: "GET",
      }),
    }),
    flight: builder.mutation<string, { sourceId: string, body?: string }>({
      query: ({ sourceId, body }) => ({
        url: `/api/v1/Sources/${sourceId}/Flight`,
        method: "POST",
        body,
      }),
    }),

    //documents
    //подгрузка документов из внешнего истоника в бекенд
    //получаю в ответ таски
    //с этих тасок, получаю когда началась синхронизация, когда закончилась
    fetchDocuments: builder.mutation<ITask, IFetchDocumentsCommand>({
      query: ({ source, range }) => ({
        url: `/api/v1/sources/${source.id}/fetch`,
        method: "POST",
        body: range || {}
      }),
      // о состоянии синхронизации мы не знаем, поэтому инвалидировать источники нет смысла
      // invalidatesTags: [{ type: "sources", id: "LIST" }],
    }),

    //получение списка тасок, когда началась синхронизация, когда закончилась
    ///tasks?id={sourceId}
    getTasks: builder.query<ITask[], string>({
      query: (souceId) => ({
        params: { id: souceId },
        url: "/api/v1/Tasks",
        method: "GET",
      }),
    }),

    //получение списка документов согласно фильтру и пагинации
    getDocuments: builder.query<
      IPagedModel<IDocument>,
      IGetDocs
    >({
      query: (params) => ({
        params,
        url: "/api/v1/documents",
        method: "GET",
      }),
      providesTags: [{ type: "documents", id: "LIST" }],
    }),

    //второй хук, а не тот же самый, потому что нам нужен другой кэш, для чеков
    //получение списка чеков согласно фильтру и пагинации
    getCheques: builder.query<
      IPagedModel<IDocument>,
      IGetDocs
    >({
      query: (params) => ({
        params,
        url: "/api/v1/documents",
        method: "GET",
      }),
      providesTags: [{ type: "cheques", id: "LIST" }],
    }),

    //получаем XML с документом
    getDocument: builder.query<string, { id: string }>({
      query: (params) => ({
        url: `/api/v1/documents/${params.id}/content`,
        method: "GET",
        responseHandler: (r) => r.text(),
        // providesTags: ["XML_document_details"],
      }),
    }),

    //deprecated
    //замена - sources/{id}/entities с type=6
    //получаем стандартизированный список всех покупалей
    // getDepartments: builder.query<IDataState<IDepartment, IDepartment>, string | void>({
    //   query: (sourceId) => ({
    //     url: `/api/v1/Documents/Departments${sourceId ? `?target=${sourceId}` : ""}`,
    //     method: "GET",
    //   }),
    //   transformResponse: (list: IDepartment[]) => {
    //     const dict: { [key: string]: IDepartment } = {};
    //     list.forEach(item => dict[item.tin] = item)
    //     return { list, dict }
    //   },
    //   providesTags: [{ type: "departments", id: "LIST" }],
    // }),

    getDepartments: builder.query<IDataState<IDepartment, IDepartment>, string>({
      query: (sourceId) => ({
        params: { type: 6 },
        url: `api/v1/sources/${sourceId}/entities`,
        method: "GET",
      }),
      transformResponse: (list: IDepartment[]) => {
        const dict: IDict<IDepartment> = {};
        list.forEach(item => dict[item.name] = item)
        return { list, dict }
      },
      providesTags: [{ type: "departments", id: "LIST" }],
    }),

    //фильтр по покупателям
    getCheckedDepartments: builder.query<IDataState<IProperty, boolean>, { companyId: string, sourceId: string }>({
      query: ({ companyId, sourceId }) => ({
        //список выбранных - /v1/member/filters?company=06f74fe0-156e-4ae5-881f-d79b70dc948e
        url: `/api/v1/member/properties/?company=${companyId}&target=${sourceId}&props=0`,
        method: "GET",
      }),
      providesTags: [{ type: "checkedDepartments", id: "LIST" }],
      transformResponse: (response: IProperty[]) => {
        const departmentFilter = response.filter(item => item.type === 0);
        //делаем словарь из массива, чтобы потом не обрабатывать двойные циклы
        const dict: { [key: string]: boolean } = {};
        departmentFilter.forEach(item => dict[item.value] = true);
        return {
          list: departmentFilter,
          dict
        }
      },
    }),

    saveCheckedDepartments: builder.mutation<IProperty[], { companyId: string, sourceId: string, departments: string[] }>({
      query: ({ companyId, sourceId, departments }) => ({
        url: `/api/v1/Member/properties?company=${companyId}&target=${sourceId}`,
        method: "POST",
        body: { "0": departments },
      }),
      invalidatesTags: [{ type: "checkedDepartments", id: "LIST" }],
    }),

    //статус одного документа, что он был выгружен и куда, номер из айки
    getDocumentStatus: builder.query<IDocumentStatus, { id: string }>({
      query: (params) => ({
        url: `/api/v1/documents/${params.id}/status`,
        method: "GET",
      }),
      providesTags: ["documentStatus"],
    }),
    //выгрузка документов
    uploadDocuments: builder.mutation<
      void,
      { source: string; documents: string[], productsStock: boolean }
    >({
      query: (payload) => ({
        url: `api/v1/sources/${payload.source}/upload?productsStock=${payload.productsStock}`,
        method: "POST",
        body: payload.documents,
      }),
      // invalidatesTags: [{ type: "documents", id: "LIST" }, { type: "documentStatus", id: "LIST" }],
      invalidatesTags: [
        { type: "documents", id: "LIST" },
        { type: "documentStatusOverride", id: "LIST" },
        "documentStatus"
      ]
    }),
    //statusOverrides - одна галочка в документе(отметка о том, что докумен тсопоставлен)
    getStatusOverrides: builder.query<IDataState<IDataStatusOverride, IDataStatusOverride>, {/*sourceId, documentsId[]]*/ sourceId: string, documentsIds: string[] }>({
      query: (payload) => ({
        url: `api/v1/documents/StatusOverrides?target=${payload.sourceId}&${arrayToParams(
          payload.documentsIds,
          "documents"
        )}`,
      }),
      transformResponse: (list: IDataStatusOverride[]) => {
        const dict: { [key: string]: IDataStatusOverride } = {};
        list.forEach(item => dict[item.id] = item)
        return { list, dict }
      },
      providesTags: [{ type: "documentStatusOverride", id: "LIST" }],
    }),

    //overrides
    //получаем оверрайды по источнику назначения
    getOverrides: builder.query<IOverride[], { source: string }>({
      query: (payload) => ({
        url: `api/v1/sources/${payload.source}/overrides`,
      }),
    }),
    saveOverrides: builder.mutation<
      IOverride[],
      { source: string; data: IOverride[] }
    >({
      query: ({ source, data }) => ({
        url: `api/v1/sources/${source}/overrides`,
        method: "POST",
        body: data,
      }),
      //TODO проверить возможно лишняя ревалидация
      invalidatesTags: [
        { type: "documents", id: "LIST" },
        { type: "documentStatusOverride", id: "LIST" },
        { type: "overrides", id: "LIST" },
        "documentStatus",
      ]
    }),

    //получаем оверрайды по документу
    getDocumentsOverrides: builder.query<
      IOverride[],
      { documents: string[]; target: string }
    >({
      // keepUnusedDataFor: 0,
      query: ({ documents, target }) => ({
        url: `api/v1/documents/overrides?target=${target}&${arrayToParams(
          documents,
          "documents"
        )}`,
        providedTags: [{ type: "overrides", id: "LIST" }]
      }),
      transformResponse: (list: IOverride[]) => {
        return list.map(override => {
          override.count = null;
          return override
        })
      },
    }),

    //получение сущностей из источника (номенклатура, склады, контрагенты и прочее)
    getEntities: builder.query<IDataState<IEntity, IEntity>, { source: string; type?: number }>({
      query: ({ source, type }) => ({
        params: { type },
        url: `api/v1/sources/${source}/entities`,
      }),
      transformResponse: (list: IEntity[]) => {
        const dict: IDict<IEntity> = {};
        list.forEach(item => dict[item.id] = item)
        return { list, dict }
      },
    }),

  }),
});

function arrayToParams(arr: unknown[], paramName: string) {
  return `${paramName}=${arr.join(`&${paramName}=`)}`;
}

export const {
  reducer,
  middleware,
  //user
  useRegisterMutation,
  useLoginMutation,
  useConfirmEmailMutation,
  //members
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useGetMembersQuery,
  //restorePassword
  useLazySendRestorePasswordMailQuery,
  useConfirmRestoreMutation,
  //companies
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  //source
  useAddSourceMutation,
  useGetSourcesQuery,
  useUpdateSourceMutation,
  useLazyGetSourcesQuery,
  useDeleteSourceMutation,
  //aggregation
  useGetAggregationsQuery,
  useSetAggregationMutation,
  //source EDS
  usePreflightMutation,
  useFlightMutation,
  //source tasks
  useGetTasksQuery,
  useLazyGetTasksQuery,
  //overrides
  // useGetOverridesQuery,
  useSaveOverridesMutation,
  useGetEntitiesQuery,
  useLazyGetEntitiesQuery,
  //doc-overrides
  useLazyGetDocumentsOverridesQuery,
  useGetDocumentsOverridesQuery,
  //document
  useGetDocumentQuery,
  useLazyGetDocumentQuery,
  useGetDocumentsQuery,
  useLazyGetDocumentsQuery,
  useFetchDocumentsMutation,
  useUploadDocumentsMutation,
  //document overrides
  useLazyGetStatusOverridesQuery,
  useGetStatusOverridesQuery,
  //cheque
  useGetChequesQuery,
  useLazyGetChequesQuery,
  //doc-status
  useGetDocumentStatusQuery,
  useLazyGetDocumentStatusQuery,
  //departments
  useGetDepartmentsQuery, //entity type 6
  useGetCheckedDepartmentsQuery,
  useSaveCheckedDepartmentsMutation,
} = api;