import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export const DocumentsIcon = ({ w = 11, h = 14, ...props }: IconProps) => {
  return (
    <Icon viewBox={`0 0 ${w} ${h}`} {...props}>
      <svg width="12" height="16" viewBox="0 1 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd" clipRule="evenodd"
          d="M11.4242 4.43905L8.06173 1.07655C7.94946 0.964289 7.79756 0.900391 7.63789 0.900391H1.00039C0.6686 0.900391 0.400391 1.1686 0.400391 1.50039V14.5004C0.400391 14.8322 0.6686 15.1004 1.00039 15.1004H11.0004C11.3322 15.1004 11.6004 14.8322 11.6004 14.5004V4.86445C11.6004 4.70512 11.5367 4.55156 11.4242 4.43905ZM7.50664 2.39494L10.1058 4.99414H7.50664V2.39494ZM1.72539 2.22539V13.7754H10.2754V6.25664H7.00039C6.79982 6.25664 6.60747 6.17696 6.46564 6.03514C6.32382 5.89332 6.24414 5.70096 6.24414 5.50039V2.22539H1.72539ZM5.87539 9.55664H3.00039C2.87641 9.55664 2.77539 9.65766 2.77539 9.78164V10.5316C2.77539 10.6556 2.87641 10.7566 3.00039 10.7566H5.87539C5.99937 10.7566 6.10039 10.6556 6.10039 10.5316V9.78164C6.10039 9.65766 5.99937 9.55664 5.87539 9.55664ZM2.77539 7.65664V8.40664C2.77539 8.53062 2.87641 8.63164 3.00039 8.63164H9.00039C9.12437 8.63164 9.22539 8.53062 9.22539 8.40664V7.65664C9.22539 7.53266 9.12437 7.43164 9.00039 7.43164H3.00039C2.87641 7.43164 2.77539 7.53266 2.77539 7.65664ZM11.3552 4.51151C11.3547 4.51093 11.3541 4.51035 11.3535 4.50977L11.3531 4.50938C11.3538 4.51008 11.3545 4.5108 11.3552 4.51151Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
};