import { Center, useToast } from "@chakra-ui/react";
import { IOverride, useSaveOverridesMutation } from "api";
import { useAppSelector } from "shared/model";
import { DictToArray } from "shared/lib";
import { useUploadDocuments } from "features/doc";

//module
import { Overrides } from "./overrides";


interface IProps {
  selection: { [key: string]: boolean };
}

export function DocMap({ selection }: IProps) {
  const sourceId = useAppSelector((x) => x.sources.current);
  const toast = useToast();
  const [saveOverrides, saveOverrideHelpers] = useSaveOverridesMutation();

  const selectedDocuments = DictToArray(selection);

  const { upload, uploadDocumentsHelpers } = useUploadDocuments();

  const doUploadStockToStock = async () => {
    if (!sourceId) return console.warn("не выбран источник");
    await upload(sourceId, selectedDocuments, false);
  };

  const onSaveOverridesHandler = (data: IOverride[]) => {
    try {
      saveOverrides({ source: sourceId, data }).unwrap();
      toast({
        description: "Сопоставления успешно сохранены",
        status: "success",
        isClosable: true,
      });
    } catch {
      console.error("ошибка сохранения оверрайдов")
    }
  };

  if (!sourceId) {
    return <Center h={"full"}>Назначение не выбрано</Center>;
  }

  return (
    <Overrides
      w={"full"}
      onSubmitted={(data) => onSaveOverridesHandler(data)}
      documents={selectedDocuments}
      upload={doUploadStockToStock}
      isLoadingUpload={uploadDocumentsHelpers.isLoading || saveOverrideHelpers.isLoading}
    />
  );
}
