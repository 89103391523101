import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { useSaveOverride } from "../model/useSaveOverride";

interface IProps extends ButtonProps {}

export const SaveOverrideButton = ({ ...buttonProps }: IProps) => {

  const {onSaveOverridesHandler, isLoading} = useSaveOverride();

  return (
    <Button
      minW={"100px"}
      isLoading={isLoading}
      onClick={onSaveOverridesHandler}
      {...buttonProps}
    >
      Сохранить
    </Button>
  );
};