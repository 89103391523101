import { ReactNode } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";

interface IProps extends BoxProps {
  label: string;
  children: ReactNode;
}

export default function WithLabel(
  {
    label,
    children,
    ...styleProps
  }: IProps) {
  return (
    <Box
      gridTemplateColumns={"auto 1fr"}
      fontSize={"xs"}
      {...styleProps}
    >
      <Text pb={"5px"}>{label}:</Text>
      <Text fontWeight={"bold"}>
        {children ?? "Не указан"}
      </Text>
    </Box>

  );
}