import { Text } from "@chakra-ui/react";
import { SourceForm } from "features/sources";

interface IProps {
  label: string,
  text?: string;
  direction: "from" | "to";
  nextStep: () => void;
}

export default function Source({ label, text, direction, nextStep }: IProps) {

  return (
    <>
      <Text pb={"15px"} variant={"heading"}> {label} </Text>
      <Text pb={"25px"} variant={"descriptor"}>{text}</Text>

      <SourceForm sideEffect={nextStep} direction={direction} />
    </>
  );
}