import { PropsWithChildren, useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { FormProvider } from "react-hook-form";

import type { IOverride } from "api";
import { useGetDocumentsOverridesQuery, useGetEntitiesQuery } from "api";

import { emptyData, useAppSelector } from "shared/model";

import { useDetailsContent } from "entities/details";
import { useGetSources } from "entities/sources";

//module
import { CombinedOverride } from "./types";
import { DetailsContext } from "./detailsContext";
import { useDetailsForm } from "../form/model/useDetailsForm";

export interface IOverrideField extends IOverride {
  temp_count: number | null,
}

export interface IOverrideDict {
  [type: string]: {
    [original: string]: IOverrideField
  };
}

export function DetailsContextProvider({ id, children }: { id: string } & PropsWithChildren) {
  const sourceId = useAppSelector((x) => x.sources.current);

  const { form: Form, isLoading: isFormLoading } = useDetailsForm({ documentId: id });

  //получаем источники
  const { data: sources } = useGetSources();
  const currentSource = sources?.find(item => item.id === sourceId);

  const { isLoading: isLoadingDocument, document } = useDetailsContent({ id });

  //при изменении sourceId перезапрашиваем entities
  const { data: products, isLoading: isLoadingProducts } = useGetEntitiesQuery(sourceId ? {
    source: sourceId,
    type: 1,
  } : skipToken);
  const { data: stocks, isLoading: isLoadingStocks } = useGetEntitiesQuery(sourceId ? {
    source: sourceId,
    type: 4,
  } : skipToken);

  //при изменении sourceId перезапрашиваем overrides
  //id не меняется
  const {
    data: overrides,
    isLoading: isLoadingDocumentsOverrides,
  } = useGetDocumentsOverridesQuery(sourceId && id ? {
    documents: [id],
    target: sourceId,
  } : skipToken);

  const isLoading = isLoadingDocument
    || isLoadingDocumentsOverrides
    || isLoadingStocks
    || isLoadingProducts
    || isFormLoading
  ;

  //Текущий склад по умолчанию
  const stock = useMemo(() => {
    const stockOverride = overrides?.find((x) => x.type === 4)?.value ?? "";
    return stocks?.dict[stockOverride];
    // return stocks?.list?.find((s) => s.id === overrides.find((x) => x.type === 4)?.value);
  }, [overrides, stocks]);

  //для таблички объединяем document.positions и overrides
  /// Список товаров объединенный с мапперами
  const positions = useMemo<CombinedOverride[]>(() => {
    if (overrides && document) {
      //TODO сюда можно воткнуть словарь
      const ovrNames = overrides.map(({ original }) => original);

      //TODO двойной цикл. матчим позиции details с оверрайдами
      return document.positions
        .filter(({ product }) => product && ovrNames.includes(product))
        .map((position) => ({
          ...overrides.find(({ original, type }) => original === position.product && type === 1)!,
          ...position,
        }));
    }
    return [];
  }, [document, overrides]);

  return (
    <DetailsContext.Provider
      {...{
        value: {
          sourceId,
          currentSource,
          stock,
          positions,
          overrides: overrides || [],
          products: products ?? emptyData,
          // doUpload,
          // isMapped: isMapped(),
          document,
          // doSaveOverrides,
          isLoading,
        },
      }}
    >
      <FormProvider {...Form}>
        <form style={{ display: "contents" }}>
          {children}
        </form>
      </FormProvider>
    </DetailsContext.Provider>
  );
}