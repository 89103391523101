import React from "react";
import { IconButton } from "@chakra-ui/react";
import { MemberModal, useMembersContext } from "features/members";
import { CTable, DeleteModal, TableBottomPanel, WithLoader } from "shared/ui";
import { AiOutlinePlus } from "react-icons/ai";

//module
import { useMemberTable } from "../model/useMemberTable";

export const MembersTable = () => {
  const { table, isLoading } = useMemberTable();

  const {
    memberModal,
    deleteModal,
    selectedMember,
    onOpenMemberModal,
    companyId,
    deleteMemberIsLoading,
    onDeleteMember
  } = useMembersContext();

  return (
    <>
      <WithLoader isLoading={isLoading} h={"80vh"}>
        <CTable
          table={table}
          isFooterDisable={true}
          footerAddon={
            <TableBottomPanel>
              <IconButton
                variant={"outline"}
                aria-label={"add member"}
                onClick={() => onOpenMemberModal?.()}
                icon={<AiOutlinePlus />}
              />
            </TableBottomPanel>
          }
        />
      </WithLoader>
      {memberModal &&
        <MemberModal
          selectedMember={selectedMember}
          disclosure={memberModal}
          mode={selectedMember ? "update" : "create"}
          companyId={companyId}
        />
      }
      {deleteModal &&
        <DeleteModal
          disclosure={deleteModal}
          isLoading={deleteMemberIsLoading}
          onDelete={() => onDeleteMember?.(selectedMember)}
          title={"Удаление пользователя"}
          message={"Удаление пользователя приведет к удалению связанных с ним данных "}
        />
      }
    </>
  );
};