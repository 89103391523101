import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export const CompanyIcon = ({ w = 14, h = 14, ...props }: IconProps) => {
  return (
    <Icon viewBox={`0 0 ${w} ${h}`} {...props}>
      <path
        d="M13.6294 1.8926L10.4895 0.0941214C10.3832 0.0324615 10.2626 0 10.1399 0C10.0171 0 9.89652 0.0324615 9.79021 0.0941214L6.99301 1.69589L4.1958 0.0941214C4.0895 0.0324615 3.96891 0 3.84615 0C3.7234 0 3.60281 0.0324615 3.4965 0.0941214L0.356643 1.8926C0.248625 1.9536 0.15868 2.04234 0.0960143 2.14973C0.0333488 2.25712 0.000213536 2.37932 0 2.5038V6.10076C0.000213536 6.22525 0.0333488 6.34744 0.0960143 6.45483C0.15868 6.56223 0.248625 6.65097 0.356643 6.71196L3.15385 8.30671V11.4962C3.15406 11.6207 3.1872 11.7429 3.24986 11.8503C3.31253 11.9577 3.40247 12.0464 3.51049 12.1074L6.65734 13.9059C6.76365 13.9675 6.88424 14 7.00699 14C7.12975 14 7.25034 13.9675 7.35664 13.9059L10.4895 12.1074C10.5975 12.0464 10.6875 11.9577 10.7501 11.8503C10.8128 11.7429 10.8459 11.6207 10.8462 11.4962V8.30671L13.6434 6.71196C13.7514 6.65097 13.8413 6.56223 13.904 6.45483C13.9667 6.34744 13.9998 6.22525 14 6.10076V2.5038C13.9984 2.37791 13.9632 2.25476 13.898 2.14723C13.8328 2.03971 13.74 1.95177 13.6294 1.8926ZM1.3986 5.69329V2.88317L3.84615 1.47811L6.29371 2.88317V5.69329L3.84615 7.09836L1.3986 5.69329ZM9.44056 11.0887L6.99301 12.4938L4.54545 11.0887V8.27861L6.99301 6.87354L9.44056 8.27861V11.0887ZM12.5874 5.69329L10.1399 7.09836L7.69231 5.69329V2.88317L10.1399 1.47811L12.5874 2.88317V5.69329Z" />
    </Icon>
  );
};