import { ReactNode } from "react";
import { CenterProps } from "@chakra-ui/react";
import { Loader } from "./Loader";

interface IProps extends CenterProps {
  children: ReactNode;
  isLoading: boolean;
  loader?: ReactNode;
}

export const WithLoader = ({
  children,
  isLoading,
  loader,
  ...styleProps
}: IProps) => {
  return (
    <>{isLoading ? loader || <Loader {...styleProps} /> : <>{children}</>}</>
  );
};
