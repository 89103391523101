import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Button, Center, Icon, Text } from "@chakra-ui/react";

import { arrow_1, CompanyIcon } from "shared/assets";

export const Bubble1 = () => {
  return (
    <Box
      position={"absolute"}
      bottom={"180px"}
    >
      <Box
        w={"230px"}
        p={"10px 0"}

        backgroundColor={"primary_btn"}
        borderRadius={"150px"}
      >
        <NavLink to={""} style={{
          padding: "0 10px",
          width: "100%",
        }}>
          <Button

            w={"full"}
            h={"40px"}
            p={2}
            justifyContent={"start"}
            backgroundColor={"inherit"}
            fontSize={"sm"}

            variant={"primary"}
          >
            <Box>
              <Center w={"30px"}>
                <Icon
                  m={"8px"}
                  h={"14px"}
                  as={CompanyIcon}
                  fill={"white"}
                />
              </Center>
            </Box>
            Предприятия
          </Button>
        </NavLink>

        <Box
          position={"absolute"}
          top={0}
          left={0}
          w={"230px"}
          h={"80px"}
        >
        </Box>
      </Box>

      <Box
        position={"absolute"}
        left={"calc(100% + 20px)"}
        bottom={"calc(-100% + 30px)"}

        w={"170px"}
        h={"50px"}

        backgroundImage={arrow_1}
        backgroundRepeat={"no-repeat"}
      >
        <Text
          position={"absolute"}
          left={"40%"}
          top={"-80%"}

          color={"white"}
          fontSize={"sm"}

          w={"250px"}
        >
          Удалить или добавить новое предприятие Вы можете тут
        </Text>
      </Box>

    </Box>
  );
};