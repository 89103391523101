import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import {
  IOverride,
  useLazyGetDocumentsOverridesQuery,
  useLazyGetDocumentStatusQuery,
  useSaveOverridesMutation,
  useUploadDocumentsMutation
} from "api";
import { ErrorUtils } from "shared/lib";
import { useAppSelector } from "shared/model";
import { useDetailsContent } from "entities/details";

//module
import { IOverrideDict, IOverrideField } from "../../details/model/detailsContextProvider";

//выгрузка документов с сохранением оверрайдов, тостами и проверками
export const useUploadDocumentsFeature = () => {
  const { id } = useParams();
  const toast = useToast();

  const sourceId = useAppSelector((x) => x.sources.current);

  const { getValues } = useFormContext();

  const [saveOverrides, saveOverridesHelpers] = useSaveOverridesMutation();
  const [getDocumentStatus, isLoadingDocumentStatus] = useLazyGetDocumentStatusQuery();
  const [getOverrides, isLoadingDocumentsOverrides] = useLazyGetDocumentsOverridesQuery();
  const [uploadDocuments, uploadDocumentsHelpers] = useUploadDocumentsMutation();

  //контент документа
  const { refetch: refetchDocumentContent, isLoading: isLoadingDocument } = useDetailsContent({ id });

  const isLoading =
    saveOverridesHelpers.isLoading
    || isLoadingDocumentStatus.isLoading
    || isLoadingDocumentsOverrides.isLoading
    || uploadDocumentsHelpers.isLoading
    || isLoadingDocument

  // Проверка, что документ сопоставлен
  const isMapped = useCallback(
    () => {
      const fields = getValues();

      //критерии сопоставленности
      //индекс сообветствует типу

      //склад внутри товара
      const product_stock_Matrix: (keyof IOverride)[][] = [
        [],
        ["value", "volume", "stock"],  //     Product,         1 продукт
        ["value"],                     //     Counterparty,    2 контрагент
        [],                            //     Measure,         3 ед. изм.
        [],                            //     Stock,           4 склад
        [],                            //     Container        5 упаковка
      ];

      //в этом наркоманском коде мы проверяем, что во вложенном объекте с полями
      //присутствуют значения, определенные в массиве mapFields
      const mapCheck = (matrix: string[][]) => {
        let res = true;
        for (const type in fields) {
          for (const hash in fields[type]) {
            matrix[+type].forEach(key => {
              if (!fields[type][hash][key as keyof IOverrideField]) {
                console.log(type, hash, key)
                res = false;
              }
            });
          }
        }
        return res
      }

      return mapCheck(product_stock_Matrix)
    },
    [getValues],
  );

  const doSaveOverrides = useCallback(async () => {
    const data = getValues();
    const overridesArr: IOverride[] = overridesDictToArray(data);
    const res = await saveOverrides({ source: sourceId, data: overridesArr });
    await getOverrides({ documents: [id || ""], target: sourceId, });
    return res;
  }, [getOverrides, getValues, id, saveOverrides, sourceId]);

  // Выгрузка сопоставления и отправка документа
  const doUpload = useCallback(async () => {

    if (!isMapped()) {
      toast({
        title: "Предупреждение",
        description: "Документ не сопоставлен",
        status: "warning",
        variant: "top-accent",
        isClosable: true,
      });
      return;
    }

    if (sourceId && id) {
      try {
        await doSaveOverrides();
        await uploadDocuments({ source: sourceId, documents: [id], productsStock: true }).unwrap()
        toast({
          description: "Документы выгружены",
          status: "success",
          isClosable: true,
        });

      } catch (e) {
        toast({
          title: "Ошибка",
          description:
            "Ошибка выгрузки документов: " + ErrorUtils.getErrorMessage(e),
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      } finally {
        await refetchDocumentContent();
        await getDocumentStatus({ id })
      }
    }
  }, [doSaveOverrides, getDocumentStatus, id, isMapped, refetchDocumentContent, sourceId, toast, uploadDocuments]);

  return {
    doUpload,
    isLoading,
  };
};

function overridesDictToArray(data: IOverrideDict) {
  const result: IOverride[] = [];

  //тайпов фиксированное количество (5)
  for (const type in data) {
    for (const hashName in data[type]) {
      result.push(data[type][hashName]);
    }
  }

  return result
}