import React, { ReactNode } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Button, Grid } from "@chakra-ui/react";

import { IDataFilter, defaultFilter } from "entities/doc";

import { useDocuments } from "features/doc";

interface IProps {
  useData: () => ReturnType<typeof useDocuments>;
  children: ReactNode;
}

export function FilterWrapper({ useData, children }: IProps) {
  const { filter, setFilter, isFetching } = useData();

  const gridTemplateColumns = "repeat(auto-fill, minmax(250px, 1fr) )";

  const FilterForm = useForm<IDataFilter>({ defaultValues: filter });
  const { handleSubmit, reset } = FilterForm;

  const onSubmit: SubmitHandler<IDataFilter> = (data) => {
    setFilter({ ...data })
  };

  const onResetFilter = () => {
    reset(defaultFilter);
    setFilter(defaultFilter);
  };

  return (
    <>
      <FormProvider {...FilterForm}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "contents" }}>
          <Grid
            gridTemplateColumns={gridTemplateColumns}
            gridRowGap={2}
            gridColumnGap={5}
          >
            {children}
          </Grid>
          <Grid gridTemplateColumns={gridTemplateColumns} gridRowGap={2} gridColumnGap={5} pt={5}>
            <Button type={"submit"} isLoading={isFetching} w={"full"}>
              Применить
            </Button>
            <Button onClick={onResetFilter} justifyContent={"start"} variant={"a"} w={"full"} textDecoration={"underline"}>
              Сбросить фильтры
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}