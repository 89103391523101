import { useCallback, useMemo } from "react";
import _ from "lodash";
import { ColumnDef, getCoreRowModel, getSortedRowModel, SortingFn, useReactTable } from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import { FormatterUtils, hashCode, trimmedString } from "shared/lib";
import { RHF_EntitySelector } from "entities/entity";
import { CombinedOverride, ProductStockSelector, useDetailsContext } from "features/doc";

//module
import { VolumeCell } from "../ui/cells/volume_cell";
import { TempCountCell } from "../ui/cells/temp_count_cell";
import { EntityMeasureCell } from "../ui/cells/entity_mesure_cell";

export const useDetailsTable = () => {
  const {
    products,
    positions,
    currentSource: source,
    document,
  } = useDetailsContext();

  const total = useMemo(() => Number(document?.sum ?? 0), [document]);

  const { getValues } = useFormContext();

  const sortEntityMeasureFn: SortingFn<CombinedOverride> = useCallback((rowA, rowB) => {
    const getHashName = (row: typeof rowA) => hashCode(row.original.original)
    const getProduct = (row: typeof rowA) => products?.dict[getValues(`[1].[${getHashName(row)}].value`)];

    const productA = getProduct(rowA)
    const productB = getProduct(rowB)

    if (!productB?.measure_name) return -1
    if (!productA?.measure_name) return 1
    return productA?.measure_name?.localeCompare(productB?.measure_name)
  }, [getValues, products?.dict])

  const columns = useMemo<ColumnDef<CombinedOverride>[]>(
    () =>
      _.isEmpty(source)
        ? []
        : [
          {
            accessorKey: "number",
            header: () => "№",
            size: 10,
            accessorFn: (cell) => cell?.number ? +cell.number : "",
          },
          {
            accessorKey: "sum",
            header: () => "Сумма с НДС",
            accessorFn: (cell) => cell?.sum ? +cell.sum : "",
            cell: ({ row }) =>
              FormatterUtils.toCurrency(Number(row.getValue("sum"))),
            footer: () => FormatterUtils.toCurrency(total),
            size: 100,
          },
          {
            accessorKey: "quantity",
            header: () => "Количество",
            size: 10,
            accessorFn: (cell) => cell?.quantity ? +cell.quantity : "Не указано",
          },
          {
            accessorKey: "measure",
            header: () => "Ед. изм.",
            size: 10,
            accessorFn: (cell) => {
              if (cell?.measure === "unknown") return "Не указано"
              return  cell?.measure ?? "Не указано"
            },
          },
          {
            accessorKey: "product",
            header: () => "Наименование",
            size: 1000,
            cell: ({ row }) => (
              <Text>
                {row.getValue("product")}
              </Text>
            ),
          },
          {
            accessorKey: "value",
            header: () => `Наименование (${trimmedString(source.name, 50)})`,
            size: 1000,
            enableSorting: false,
            cell: ({ row }) => {

              let fieldName = ""
              if (row?.original?.product) fieldName = `[1].[${hashCode(row?.original?.product)}].value`;

              return (
                <RHF_EntitySelector
                  name={fieldName}
                  isHideArrow={true}
                  source={source.id}
                  type={1}
                  isAsync
                  placeholder={"Не сопоставлено"}
                  allowNull
                />
              )
            },
          },
          {
            accessorKey: "count",
            header: () => "Количество",
            size: 220,
            enableSorting: false,
            cell: ({ row }) => <TempCountCell row={row} />,
          },
          {
            accessorKey: "entity_measure",
            header: () => "Ед. изм.",
            size: 10,
            sortingFn: sortEntityMeasureFn,
            cell: ({ row }) => <EntityMeasureCell row={row} products={products} />,
          },
          {
            accessorKey: "volume",
            header: () => "Коэффициент",
            size: 150,
            enableSorting: false,
            cell: ({ row }) => <VolumeCell row={row} />,
          },
          {
            accessorKey: "stock",
            header: () => "Склад",
            size: 800,
            enableSorting: false,
            cell: ({ row }) => {

              let fieldName = ""
              if (row?.original?.product) fieldName = `[1].[${hashCode(row?.original?.product)}].stock`;

              return (
                <ProductStockSelector
                  name={fieldName}
                  source={source.id}
                  type={4}
                />
              )
            },
          },
        ],
    [source, sortEntityMeasureFn, total, products],
  );

  const table = useReactTable({
    columns,
    data: positions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // console.log("render", new Date());
  // console.log("products", products)
  return {
    table
  }
}