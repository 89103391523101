import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup, HStack, Spacer } from "@chakra-ui/react";
import { SourceSelect } from "entities/sources";

interface IProps {
  doUpload: () => Promise<void>;
  linkToMap: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const ButtonPanel = (
  {
    doUpload,
    linkToMap,
    isLoading,
    isDisabled
  }: IProps) => {
  // const { t } = useTranslation();
  const nav = useNavigate();

  return (
    <HStack>
      <Spacer />

      <SourceSelect />

      <ButtonGroup variant="outline" spacing="2">
        <Button
          variant={"primary"}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={() => nav(linkToMap)}
        >
          {/*{t("assign")}*/}
          Сопоставить
        </Button>
        <Button
          variant={"primary"}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={doUpload}
        >
          Выгрузить
        </Button>
      </ButtonGroup>
    </HStack>
  );
};