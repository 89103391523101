import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Center, FormControl, Grid, Icon, SystemStyleObject, Text, Tooltip } from "@chakra-ui/react";
import { useGetEntitiesQuery } from "api";

import { MenuPlacement, MultiValue, } from "chakra-react-select";

import { AsyncBaseSelect, BaseSelect, CProgress } from "shared/ui";

import { IconType } from "react-icons";

import { MdOutlineShoppingCart, MdOutlineWorkOutline } from "react-icons/md";
import { IoFastFoodOutline } from "react-icons/io5";
import { PiBeerBottle } from "react-icons/pi";
import { TbMeat } from "react-icons/tb";
import { skipToken } from "@reduxjs/toolkit/query";

//module
import { IEntity } from "../../model/entity";
import { convertToOption } from "../model/convertToOption";
import { IEntityOption } from "../model/type";

const icons: { [key: string]: IconType } = {
  DISH: IoFastFoodOutline,
  GOODS: MdOutlineShoppingCart,
  MODIFIER: PiBeerBottle,
  PREPARED: TbMeat,
  SERVICE: MdOutlineWorkOutline,
}

const names: { [key: string]: string } = {
  DISH: "Блюдо",
  GOODS: "Товар",
  MODIFIER: "Модификатор",
  PREPARED: "Заготовка",
  SERVICE: "Услуга",
}

// DISH  Блюдо.
// GOODS  Товар.
// MODIFIER  Модификатор.
// PREPARED  Заготовка (полуфабрикат).
// SERVICE  Услуга.

// OUTER  Товары поставщиков, не являющиеся товарами систем iiko.
// RATE  Тариф (дочерний элемента для услуги).

export interface IEntitySelectorProps {
  type: number;
  source: string;
  defaultValue?: string | null;
  allowNull?: boolean;
  filter?: (e: IEntity) => boolean;
  onChanged?: (s?: IEntity) => void;
  label?: string;
  isAsync?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  menuPortalTarget?: HTMLElement;
  isHideArrow?: boolean;
  additionalChakraStyles?: (arg: SystemStyleObject) => SystemStyleObject,
  isNonRoundedLeft?: boolean;
}

export function EntitySelector(
  {
    onChanged,
    filter,
    allowNull,
    isAsync,
    isDisabled = false,
    menuPortalTarget,
    placeholder = "Выбрать",
    isHideArrow = false,
    isNonRoundedLeft = false,
    ...props
  }: IEntitySelectorProps) {
  const { data: entities, isLoading } = useGetEntitiesQuery(props.source ? { source: props.source, type: props.type, } : skipToken);
  const data = entities?.list

  const ref = useRef<HTMLDivElement | null>(null);
  const [menuPlaceMent, setMenuPlacement] = useState<MenuPlacement>("bottom");

  const options = useMemo(
    () => [
      ...(data
          ? (filter ? data.filter(filter) : data).map(convertToOption)
          : []
      ).sort((a, b) => a.label.localeCompare(b.label)),
    ],
    [data, filter],
  );

  const value = useMemo(
    () => {
      const find = entities?.dict[props.defaultValue as string];
      return find ? convertToOption(find) : null
    },
    [entities?.dict, props.defaultValue],
  );

  const onChange = useCallback(
    (item?: MultiValue<IEntityOption> | unknown) => {
      if (onChanged) {
        if (item && data) {
          onChanged(
            Array.isArray(item)
              ? data.find((x) => item.map((x) => x.value).includes(x.id))
              : entities?.dict[(item as IEntityOption).value as string]
          );
        } else {
          onChanged();
        }
      }
    },
    [onChanged, data, entities?.dict],
  );

  //устанавливает всплывающий список селекта в правильное положение
  const onOpenMenuClickHandler = useCallback(() => {
    if (!ref?.current) return;
    if (ref?.current?.getBoundingClientRect().bottom > 360) {
      if (menuPlaceMent === "top") return;
      return setMenuPlacement("top");
    }
    if (menuPlaceMent === "bottom") return;
    return setMenuPlacement("bottom");
  }, [menuPlaceMent]);

  useEffect(() => {
    onOpenMenuClickHandler();
  }, [onOpenMenuClickHandler]);

  const formatOptionLabel = (data: IEntityOption) => {
    return (
      <>
        <Tooltip label={names?.[data?.type_id ?? ""]} placement={"right"}>
          <Box
            w={"30px"} h={"30px"}
            position={"absolute"} right={0}
          >
          </Box>
        </Tooltip>
        <Grid
          w={"full"}
          gap={2}
          gridTemplateColumns={"1fr auto 20px"}
          alignItems={"center"}

          fontSize={"sm"}
        >
          <Box
            w={"full"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            pr={"10px"}
          >
            {data?.label}
          </Box>

          <Box>
            {data?.num}
          </Box>

          <Center>
            <Icon as={icons?.[data?.type_id ?? ""]} color={"primary_btn_outline"} w={6} h={6} />
          </Center>
        </Grid>
      </>
    )
  }


  // <Select
  //   {...props}
  //   className="react-select-container"
  //   classNamePrefix="react-select"
  // />
  //
  // ...the DOM structure is similar to this:
  //
  // <div class="react-select-container">
  //   <div class="react-select__control">
  //     <div class="react-select__value-container">...</div>
  //     <div class="react-select__indicators">...</div>
  //   </div>
  //   <div class="react-select__menu">
  //     <div class="react-select__menu-list">
  //       <div class="react-select__option">...</div>
  //     </div>
  //   </div>
  // </div>

  const nonRoundedLeftStyle = {
    control: (provided: SystemStyleObject) => (
      {
        ...provided,
        borderRadius: isNonRoundedLeft ? "0 5px 5px 0" : provided.borderRadius,
      })
  }

  return (
    <FormControl w={"full"} minW={"15rem"} ref={ref} onMouseOver={onOpenMenuClickHandler}>
      {isLoading ? (
        <Grid pl={3} pr={3} w={"full"} h={"full"}>
          <CProgress />
        </Grid>
      ) : isAsync ? (
        <>
          <AsyncBaseSelect<IEntityOption>
            {...{ options, onChange, isDisabled }}
            value={value || null}
            placeholder={placeholder}
            isClearable={allowNull}
            isReadOnly={isLoading}
            isHideArrow={isHideArrow}

            //menuPlacement
            menuPlacement={menuPlaceMent}
            menuPortalTarget={menuPortalTarget}
            menuPosition={"fixed"}
            maxMenuHeight={240}

            // filterOption={createFilter({ ignoreAccents: true })}
            noOptionsMessage={({ inputValue }) => {
              let message = `"${inputValue}" не найдено`;
              if (inputValue.length < 3) message = "Введите более 3х символов";
              if (inputValue === "") message = "Начните ввод";
              return (
                <Text textAlign={"start"} pl={"1.2rem"}>
                  {message}
                </Text>
              )
            }}

            loadOptions={(inputValue, callback) => {
              const filteredOptions = options
                .filter((option) =>
                    option?.label?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option?.num?.toLowerCase().includes(inputValue.toLowerCase())
                  ,)
                .slice(0, 200)
              return callback(inputValue.length >= 3 ? filteredOptions : [],)
            }}

            formatOptionLabel={formatOptionLabel}
            additionalChakraStyles={nonRoundedLeftStyle}
          />
        </>
      ) : (
        <>
          <BaseSelect<IEntityOption>
            {...{ options, onChange, isDisabled }}
            value={value || null}
            placeholder={placeholder}
            isClearable={allowNull}
            isReadOnly={isLoading}
            isHideArrow={isHideArrow}

            // filterOption={createFilter({ ignoreAccents: true })}
            noOptionsMessage={() => <Text>Нет элементов</Text>}

            //menuPlacement
            menuPlacement={menuPlaceMent}
            menuPortalTarget={menuPortalTarget}
            menuPosition={"fixed"}
            maxMenuHeight={240}

            additionalChakraStyles={nonRoundedLeftStyle}
          />
        </>
      )}
    </FormControl>
  )
}