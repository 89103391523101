import { createContext, PropsWithChildren, useContext } from "react";
import { useForm } from "react-hook-form";

interface IStockContext {
  localForm: ReturnType< typeof useForm>;
}

export const StockContext = createContext<IStockContext | null>(null);

export function useStockContext() {
  const contextValue = useContext(StockContext);
  if (contextValue === null) throw new Error("не найден контекст useStockContext");
  return contextValue;
}

export function StockContextProvider({ children }: PropsWithChildren) {
  const localForm = useForm();
  return (
    <StockContext.Provider
      {...{
        value: {
          localForm
        }
      }}
    >
      {children}
    </StockContext.Provider>
  );
}