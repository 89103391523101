import React from "react";
import moment from "moment";
import { IconButton } from "@chakra-ui/react";
import { HiOutlineRefresh } from "react-icons/hi";
import { ISource } from "entities/sources";
import { useFetchSource } from "../model/useFetchSource";

interface IProps {
  source: ISource;
}

export const RefreshSourceButton = ({ source }: IProps) => {
  const { isLoading, fetchDocumentsHelpers, fetchSource } = useFetchSource({source});
  return (
    <IconButton
      variant={"outline"}
      icon={<HiOutlineRefresh />}
      aria-label={"refresh source"}
      onClick={() => fetchSource({source})}
      isLoading={fetchDocumentsHelpers.isLoading || isLoading}
    />
  );
};