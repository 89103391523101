import { ReactNode } from "react";
import { Box, Center } from "@chakra-ui/react";

import { LogoWrapper } from "shared/ui";

export default function WizardWrapper({ children }: { children: ReactNode }) {
  return (
    <Center h={"full"} backgroundColor={"main_bg"} flexGrow={2}>
      <LogoWrapper>
        <Box backgroundColor={"primary_bg"}>
          <Box
            w={"480px"}
            borderRadius={5} p={"40px"}
            background={"linear-gradient(180deg, rgba(171, 199, 255, 0.50) 0%, rgba(255, 255, 255, 0.20) 60.99%, rgba(255, 255, 255, 0.20) 100%)"}
          >
            {children}
          </Box>
        </Box>
      </LogoWrapper>
    </Center>
  );
}