import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetAggregationsQuery } from "api";
import { ISource } from "entities/sources";

import { useFormContext } from "react-hook-form";
import { WithLoader } from "shared/ui";

interface IProps {
  source?: ISource;
  label?: string;
  isDisabled?: boolean;
  name?: string;
}

const AggregationInput = ({
  source,
  label,
  isDisabled = false,
  name = "aggregation",
}: IProps) => {
  const { data, isLoading: isLoadingData } = useGetAggregationsQuery(
    source?.type === 2 ? source?.id : skipToken
  );

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors?.[name];
  const errorMessage = error?.message?.toString();

  return (
    <>
      <WithLoader
        isLoading={isLoadingData}
        loader={
          <Center w={"full"} h={"66px"}>
            <Spinner size={"xl"} color={"primary_btn"} />
          </Center>
        }
      >
        {data && (
          <FormControl isInvalid={!!errors?.[name]} w={"full"}>
            {label && (
              <FormLabel>
                <Text variant={"form_input_label"}> {label} </Text>
              </FormLabel>
            )}

            <Select
              w={"full"}
              variant={"md"}
              {...register(name, {
                required: { value: true, message: "Обязательно к заполнению" },
              })}
              isDisabled={isDisabled}
              placeholder="Не выбран"
            >
              {data?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.fullName}
                </option>
              ))}
            </Select>

            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
        )}
      </WithLoader>
    </>
  );
};

export { AggregationInput };
