import React from "react";
import moment from "moment";
import { Box, Center, Flex, Grid, Text, Tooltip } from "@chakra-ui/react";

import { useAppSelector } from "shared/model";
import { CProgress } from "shared/ui";
import { useGetSources } from "entities/sources";
import { useDocumentStatus } from "entities/doc_status";

export function DocumentStatus({ id }: { id: string }) {
  const sourceId = useAppSelector((x) => x.sources.current);
  const { data: sources } = useGetSources();
  const selectedSource = sources?.find(item => item.id === sourceId)

  //получаем статус данного документа
  const { status, isLoading } = useDocumentStatus({ id });

  if (isLoading) return (
    <Flex alignItems={"center"} justifyContent={"end"} flexGrow={1}>
      <Box h={"9px"} w={"500px"}>
        <CProgress />
      </Box>
    </Flex>
  )

  return status ? (
    <Center flexGrow={1} p={"0 20px"}>
      <Grid gridTemplateColumns={"repeat(3, auto)"}>
        <Tooltip label={selectedSource?.name.slice(0, 800)}>
          <Text
            fontSize={"md"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
          >
            Номер в {selectedSource?.name}
          </Text>
        </Tooltip>
        <Text fontSize={"md"}>
          :{" "}{status?.number}
        </Text>
        <Text
          fontSize={"md"}
          pl={"20px"}
        >
          Дата выгрузки:{" "}{moment(status?.changed).local().format("DD.MM.YYYY HH:mm:ss")}
        </Text>
      </Grid>
    </Center>
  ) : (
    <Box flexGrow={1}></Box>
  );
}