import React from "react";
import { Box, Button, HStack, Icon, Link, Menu, MenuButton, MenuItem, MenuList, Spacer, Text } from "@chakra-ui/react";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";

interface IProps {
  isOpen: boolean;
}

export function HelpBar({ isOpen }: IProps) {
  const version = localStorage.getItem("app_version");

  return (
    <Menu
      placement={"right-end"}
      variant={"help"}
      gutter={20}
    >
      <MenuButton
        as={Box}
        w={"calc(100% - 20px)"}
        h={"40px"}
        m={"0 10px"}
        borderRadius={"5px"}
        _hover={{
          backgroundColor: "primary_btn_active"
        }}
        transition={"all 0.2s"}
      >
        <Button
          w={"full"}
          h={"40px"}
          p={2}
          justifyContent={"start"}
          backgroundColor={"inherit"}
          fontSize={"sm"}
          display={"inline-flex"}
        >
          <Icon
            p={"6.5px"}
            h={"30px"}
            w={"30px"}
            as={HiOutlineQuestionMarkCircle}
          />

          {isOpen && "Помощь"}
        </Button>

      </MenuButton>


      <MenuList
        p={0}
        zIndex={999}
        borderRadius={"10px"}
        overflow={"hidden"}
      >
        <Box
          backgroundColor={"primary_btn"}
          w={"500px"}
          p={"30px 40px"}
        >
          <Text color={"white"} fontSize={"32px"}>
            Появились вопросы?
          </Text>

          {/*Не трогать, это на новый год*/}

          {/*<Text pt={"20px"} color={"white"} fontSize={"16px"}>*/}
          {/*  Мы позаботились о Вас и сделали инструкцию*/}
          {/*</Text>*/}

        </Box>
        <Box p={"5px 40px"}>


          {/*Не трогать, это на новый год*/}


          {/*<MenuItem>*/}
          {/*  <HStack w={"full"}>*/}
          {/*    <Text>*/}
          {/*      Инструкция*/}
          {/*    </Text>*/}
          {/*    <Spacer />*/}
          {/*    <Link href={"mailto:support@swapdog.ru"}>*/}
          {/*      <Button w={"90px"}>*/}
          {/*        Скачать*/}
          {/*      </Button>*/}
          {/*    </Link>*/}
          {/*  </HStack>*/}
          {/*</MenuItem>*/}

          {/*<MenuDivider />*/}

          {/*<MenuItem>*/}
          {/*  <HStack w={"full"}>*/}
          {/*    <Text>*/}
          {/*      Не нашли ответ?*/}
          {/*    </Text>*/}
          {/*    <Spacer />*/}
          {/*    <HStack gap={0} w={"90px"} justifyContent={"space-between"}>*/}
          {/*      /!*<Icon as={BiPhoneCall } w={"30px"} h={"30px"} color={"primary_btn"} opacity={0.4}/>*!/*/}
          {/*      /!*<Icon as={BiMailSend } w={"30px"} h={"30px"} color={"primary_btn"} opacity={0.4}/>*!/*/}
          {/*      /!*<Icon as={BiLogoTelegram } w={"30px"} h={"30px"} color={"primary_btn"} opacity={0.4}/>*!/*/}

          {/*      <IconButton variant={"social"} aria-label={"contacts"} icon={<BiPhoneCall />} />*/}
          {/*      <IconButton variant={"social"} aria-label={"contacts"} icon={<BiMailSend />} />*/}
          {/*      <IconButton variant={"social"} aria-label={"contacts"} icon={<BiLogoTelegram />} />*/}
          {/*    </HStack>*/}
          {/*  </HStack>*/}
          {/*</MenuItem>*/}

          {/*<MenuDivider />*/}

          <MenuItem>
            <HStack w={"full"}>
              <Text>
                Свяжитесь с нами
              </Text>
              <Spacer />
              <Link pl={"10px"} color={"a"} href={"mailto:support@swapdog.ru"}> support@swapdog.ru </Link>
            </HStack>
          </MenuItem>

          <MenuItem>
            <HStack w={"full"}>
              <Text>
                Версия приложения
              </Text>
              <Spacer />
              <Text>
                v {version}
              </Text>
            </HStack>
          </MenuItem>


        </Box>
      </MenuList>
    </Menu>
  );
}