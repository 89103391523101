import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { Box, ButtonGroup, Center, FormControl, Grid, HStack, InputGroup, InputLeftAddon, Spacer, Text, } from "@chakra-ui/react";
import { appSettings, hashCode } from "shared/lib";
import { ButtonPanelWrapper, PageWithHeading } from "shared/ui";
import { SourceSelect, useGetSources } from "entities/sources";
import { RHF_EntitySelector } from "entities/entity";
import { AutoFillStockSelector, UploadButton, useDetailsContext } from "features/doc";
import { SaveOverrideButton } from "features/saveOverride";

//module
import { DocumentStatus } from "./documentStatus";
import ProductsTable from "./products_table";

export const Details = () => {
  const { id: documentId } = useParams();
  const { sourceId, document, overrides } = useDetailsContext();
  const { data: sources } = useGetSources();
  const effectiveStockOriginal = useMemo(() => {
      return document?.consignee || document?.buyer
    },
    [document?.buyer, document?.consignee],
  );

  const canUploadToSources = useMemo(() => sources?.filter(({ type }) => appSettings.source.get(type)?.canUploadToSource), [sources])

  //TODO под вопросом, возможно достать из details
  //FIND
  const stock = useMemo(() => {
    //trim потому, что есть баги, что с бека приходит пустая строка/пробел, которые не матчатся
    return overrides.find(
      ({ original, type }) => type === 4 && original.trim() === effectiveStockOriginal?.trim(),
    );
  }, [overrides, effectiveStockOriginal]);

  //TODO под вопросом, возможно достать из details
  //FIND
  // Маппинг поставщика
  const seller = useMemo(() => {
    return overrides.find(
      ({ original, type }) => type === 2 && original === document?.seller,
    );
  }, [document, overrides]);

  const date = moment(document?.date, ["YYYY-MM-DD", "DD.MM.YYYY"]).format("DD.MM.YYYY");

  return (
    <PageWithHeading
      label={document?.number ? `Накладная №${document?.number} от ${date}` : ""}
      rightElement={
        sourceId && stock &&
        <FormControl w={"25vw"}>
          <InputGroup variant={"primary"}>
            <InputLeftAddon>
              Склад для всех строк
            </InputLeftAddon>

            <AutoFillStockSelector
              name={`[4].[${hashCode(stock.original)}].value`}
              source={sourceId}
              type={4}
            />
          </InputGroup>
        </FormControl>
      }
    >

      <Box overflow={"auto"} h={"full"}>
        <Grid gridTemplateRows={"1fr auto"} h={"full"}>
          {
            canUploadToSources &&
            canUploadToSources?.length !== 0 ?
              <>
                {sourceId ?
                  <Box overflow={"auto"}>
                    <Grid gridTemplateRows={"1fr"} maxH={"full"}>
                      <Box overflow={"auto"}>
                        <ProductsTable />
                      </Box>
                    </Grid>
                  </Box>
                  :
                  < Center w={"full"} h={"full"}>
                    <Text fontSize={"md"}>
                      Выберите назначение
                    </Text>
                  </Center>
                }
              </>
              :
              <>
                <Center w={"full"} h={"full"}>
                  <Text fontSize={"md"}>
                    Создайте источник для выгрузки
                  </Text>
                </Center>
              </>
          }

          <ButtonPanelWrapper>
            <HStack w={"full"}>

              <SaveOverrideButton />

              <DocumentStatus id={documentId || ""} />
              <Spacer />
              {sourceId && seller &&
                <FormControl maxWidth={"20vw"}>
                  <InputGroup variant={"primary"}>
                    <InputLeftAddon>
                      Поставщик:
                    </InputLeftAddon>

                    <RHF_EntitySelector
                      name={`[2].[${hashCode(seller.original)}].value`}
                      type={2}
                      isHideArrow={true}
                      source={sourceId}
                      placeholder={"Не сопоставлено"}
                      allowNull
                      isNonRoundedLeft={true}
                    />

                  </InputGroup>
                </FormControl>
              }


              <HStack gap={2} justifyContent={"end"}>
                <SourceSelect />
                <ButtonGroup>
                  <UploadButton />
                </ButtonGroup>
              </HStack>

            </HStack>
          </ButtonPanelWrapper>
        </Grid>
      </Box>

    </PageWithHeading>
  );
}