import { useMemo } from "react";
import { ColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { FormControl, Input } from "@chakra-ui/react";
import { IOverride, useGetEntitiesQuery } from "api";
import { CTable } from "shared/ui";
import { trimmedString, validateNumber } from "shared/lib";
import { OverrideSelector } from "entities/entity";
import { ISource } from "entities/sources";
import { skipToken } from "@reduxjs/toolkit/query";

export function ProductsOverrides(
  {
    data,
    target,
    onChanged,
  }: {
    data: IOverride[];
    target: ISource;
    onChanged?: (e: IOverride) => void;
  }) {

  const { data: products } = useGetEntitiesQuery(target?.id ? { source: target?.id, type: 1 } : skipToken);

  const columns = useMemo(
    (): ColumnDef<IOverride>[] => [
      {
        id: "original",
        accessorKey: "original",
        header: () => "Наименование",
        size: 500,
      },
      {
        id: "value",
        accessorKey: "value",
        header: () => `Наименование (${trimmedString(target.name, 50)})`,
        size: 500,
        enableSorting: false,
        cell: ({ row }) => (
          <OverrideSelector
            isHideArrow={true}
            allowNull={true}
            isRequired
            isAsync
            type={1}
            source={target}
            value={row.getValue("value")}
            onChange={(e) => onChanged?.({ ...row.original, value: e })}
          />
        ),
      },
      {
        id: "volume",
        accessorKey: "volume",
        size: 100,
        header: () => "Коэффициент",
        cell: ({ row }) => (
          <FormControl>
            <Input
              // w={"128px"}
              min={0}
              max={999_999_999}
              step={"any"}
              type="number"
              fontSize={"sm"}
              defaultValue={validateNumber(row.getValue("volume"))}
              onBlur={(e) =>
                onChanged?.({
                  ...row.original,
                  volume: +validateNumber(e.target.value)
                })
              }
            />
          </FormControl>
        ),
      },
      {
        id: "measure",
        header: () => "Ед. изм",
        size: 100,
        //оптимизировал перебор большого числа entity на каждой строке
        cell: ({ row }) => <>{products?.dict[row.original.value as string]?.measure_name}</>,
      },
    ],
    [target, onChanged, products]
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  console.log("products", products)

  return <CTable table={table} />
}