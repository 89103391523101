import { ReactNode, useCallback, useState, } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { useDeleteMemberMutation } from "api";
import { IMember } from "entities/members";

//local
import { MembersContext } from "./membersContext";

interface IProps {
  children: ReactNode;
}

export function MembersProvider({ children }: & IProps) {
  const { companyId } = useParams();
  const [deleteMember, deleteMemberHelpers] = useDeleteMemberMutation();
  const deleteMemberIsLoading = deleteMemberHelpers.isLoading;
  const toast = useToast();

  const memberModal = useDisclosure();
  const deleteModal = useDisclosure();
  const [selectedMember, setSelectedMember] = useState<IMember>()

  const onDeleteMember = async (member?: IMember) => {
    console.log("onDeleteMember", member)
    if (!member) return
    try {
      await deleteMember({ ...member, companyId }).unwrap()
      toast({
        description: "Пользователь удален",
        status: "success",
        isClosable: true,
      });
      deleteModal.onClose();
    } catch (e) {
      console.warn("Ошибка удаления пользователя", e);
    }
  }

  const onOpenMemberModal = useCallback((member?: IMember) => {
    console.log("onOpenMemberModal", member)
    setSelectedMember(member);
    memberModal.onOpen();
  }, [memberModal])

  const onOpenDeleteModal = useCallback((member: IMember) => {
    console.log("onCopenDeleteModal")
    setSelectedMember(member);
    deleteModal.onOpen();
  }, [deleteModal])

  return (
    <MembersContext.Provider
      {...{
        value: {
          memberModal,
          deleteModal,
          onDeleteMember,
          deleteMemberIsLoading,
          onOpenMemberModal,
          onOpenDeleteModal,
          selectedMember,
          companyId,
          // setSelectedMember,
        },
      }}
    >
      {children}
    </MembersContext.Provider>
  );
}