import React from "react";
import { Box, Text } from "@chakra-ui/react";

import { arrow_2 } from "shared/assets";
import { CompanyMenuButton } from "features/companies";

export const Bubble2 = () => {
  return (
    <Box
      position={"absolute"}
      top={"-10px"}
      right={0}
    >
      <Box
        p={"30px 20px"}
        backgroundColor={"main_bg"}
        borderRadius={"150px"}
      >
        <CompanyMenuButton />
        <Box
          position={"absolute"}
          top={0}
          left={0}
          w={"300px"}
          h={"80px"}
        >
        </Box>

        <Box
          position={"absolute"}
          left={"-30px"}
          bottom={"-160px"}

          w={"150px"}
          h={"150px"}

          backgroundImage={arrow_2}
          backgroundRepeat={"no-repeat"}
        >
          <Text
            position={"absolute"}
            left={"-50%"}
            bottom={0}

            color={"white"}
            fontSize={"sm"}

            w={"250px"}
          >
            Быстро переключиться между предприятиями можно тут
          </Text>
        </Box>

      </Box>
    </Box>
  );
};