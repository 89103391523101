import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAggregationState } from "./types";

const initialState: IAggregationState = {
  hideBannerFlag: false,
};

const slice = createSlice({
  initialState,
  name: "aggregation_banner",
  reducers: {
    reset: () => initialState,
    setHideBannerFlag: (state, { payload }: PayloadAction<boolean>) => {
      state.hideBannerFlag = payload;
    },
  },
});

export const actions = { ...slice.actions };
export const { reducer } = slice;
