import { useCallback } from "react";
import _ from "lodash/fp";
import { useFormContext } from "react-hook-form";
import { useToast } from "@chakra-ui/react";

import { useAppSelector } from "shared/model";
import { IOverride, useSaveOverridesMutation } from "api";

//TODO поправить импорт
import { IOverrideDict } from "../../doc/details/model/detailsContextProvider";

export const useSaveOverride = () => {
  const toast = useToast();
  const sourceId = useAppSelector((x) => x.sources.current);
  const { formState: { errors }, getValues } = useFormContext();

  // //сохранение оверрайдов
  const [saveOverrides, saveOverridesHelpers] = useSaveOverridesMutation();

  const onSaveOverridesHandler = useCallback(async () => {

    if (!_.isEmpty(errors)) {
      toast({
        title: "Ошибки в полях ввода",
        description: "Проверьте правильность введенных данных",
        status: "warning",
        variant: "top-accent",
        isClosable: true,
      });
      return
    }

    const data = getValues();
    const overridesArr: IOverride[] = overridesDictToArray(data);

    const res = await saveOverrides({ source: sourceId, data: overridesArr });

    const resOk = res as { data: IOverride[] };
    if (!resOk?.data) return;
    toast({
      title: "Информация",
      description: "Сопоставления сохранены",
      status: "success",
      variant: "top-accent",
      isClosable: true,
    });
  }, [errors, getValues, saveOverrides, sourceId, toast])
  return {
    onSaveOverridesHandler,
    isLoading: saveOverridesHelpers.isLoading
  }
}

function overridesDictToArray(data: IOverrideDict) {
  const result: IOverride[] = [];

  //тайпов фиксированное количество (5)
  for (const type in data) {
    for (const hashName in data[type]) {
      result.push(data[type][hashName]);
    }
  }

  return result
}