import { useCallback, useEffect, useMemo } from "react";
import { IDocument, IGetDocs, useGetChequesQuery, useGetStatusOverridesQuery, useLazyGetChequesQuery } from "api";

import { useAppDispatch, useAppSelector } from "shared/model";

import { skipToken } from "@reduxjs/toolkit/query";
import moment from "moment";

import { DictToArray } from "shared/lib";

import { ChequesActions, DocumentsActions, IDataFilter, IDataPaginationState, IDataState } from "entities/doc";

//module
import { useUploadDocuments } from "../uploadDocuments/model/useUploadDocuments";

interface IUseData {
  type?: 0 | 128;
  documentState: IDataState;
  getDataQuery: typeof useGetChequesQuery;
  getLazyDataQuery: typeof useLazyGetChequesQuery;
  actions: typeof DocumentsActions | typeof ChequesActions;
}

export function useData(
  {
    type,
    documentState,
    getDataQuery,
    actions,
  }: IUseData) {

  const d = useAppDispatch();
  const company = useAppSelector((x) => x.companies.current);
  const currentSourceId = useAppSelector(x => x.sources.current);
  // const [uploadDocuments, uploadDocumentsHelpers] = useUploadDocumentsMutation();

  const formatDate = (date?: string) => moment(date).toISOString();

  const docParams: IGetDocs = useMemo(() => (
    {
      type: type,
      company: company!.id,
      page: documentState.pager.pageIndex + 1,
      pageSize: documentState.pager.pageSize,
      orderBy: documentState.sort.field,
      orderMode: documentState.sort.mode,
      targetUpload: currentSourceId,
      //filter
      ...documentState.filter,
      isUpload: documentState.filter.isUpload ?? undefined,
      ///filter
      //TODO перенести перевод даты на уровень ниже
      from: formatDate(documentState.filter.from),
      to: formatDate(documentState.filter.to),
    }
  ), [company, currentSourceId, documentState.filter, documentState.pager.pageIndex, documentState.pager.pageSize, documentState.sort.field, documentState.sort.mode, type]);

  const {
    data: documents,
    isSuccess: isSuccessDocuments,
    isFetching,
    refetch: refetchGetDocumentsQuery,
  } = getDataQuery(company ? docParams : skipToken);

  const { data: statusOverrides, isLoading: isLoadingDocumentStatuses } = useGetStatusOverridesQuery(
    currentSourceId &&
    documents?.data?.length !== 0 &&
    isSuccessDocuments ?
      {
        sourceId: currentSourceId,
        documentsIds: documents?.data?.map(item => item.id)
      }
      :
      skipToken,
  );

  const { upload: uploadDocuments, uploadDocumentsHelpers } = useUploadDocuments();
  const upload = (source: string, documents?: string[]) => uploadDocuments(source, documents ?? DictToArray(documentState.selection), true)

  const setCurrent = useCallback(
    (doc?: IDocument) => {
      d(actions.setCurrent(doc));
    },
    [actions, d],
  );

  const setSelection = useCallback(
    (docs: string[]) => {
      d(actions.setSelection(docs));
    },
    [actions, d],
  );

  const setFilter = useCallback(
    async (filter: Partial<IDataFilter>) => {
      d(actions.setFilter(filter));
      if (!documents) return;
      if (!documents?.data) return;
      d(actions.refreshSelection(documents.data));
      await refetchGetDocumentsQuery();
      // await refetchStatusOverrides();
    },
    [actions, d, documents, refetchGetDocumentsQuery],
  );

  const setPager = useCallback(
    async (s: IDataPaginationState) => {
      d(actions.setPager(s));
    },
    [actions, d],
  );

  //если список документов изменился, проверить чекбоксы
  useEffect(() => {
    if (!documents?.data) return;
    d(actions.refreshSelection(documents?.data));
  }, [actions, d, documents?.data])

  const setSort = useCallback(
    async (field?: string, mode: "asc" | "desc" = "asc") => {
      d(actions.setSort({ field, mode }));
    },
    [actions, d],
  );

  // Upload mapped docs to source
  // const upload = useCallback(
  //   (source: string, documents?: string[]) => {
  //     app.lock();
  //     return uploadDocuments({
  //       source,
  //       documents: documents ?? DictToArray(documentState.selection),
  //     })
  //       .unwrap()
  //       .then(() => app.setMessage("Документы выгружены"))
  //       .catch(() => app.setError("Ошибка выгрузки документов"))
  //       .finally(() => app.unlock());
  //   },
  //   [app, uploadDocuments, documentState.selection],
  // );

  return {
    ...documentState,
    statusOverrides,
    documents: documents,
    setCurrent,
    setSelection,
    setFilter,
    setPager,
    setSort,
    isLoadingDocumentStatuses,
    uploadDocumentsHelpers,
    refetchGetDocumentsQuery,
    upload,
    isFetching,
  };
}