import { CheckIcon } from "@chakra-ui/icons";
import { Center, HStack, IconButton, Spinner } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetAggregationsQuery, useSetAggregationMutation } from "api";
import { ISource } from "entities/sources";
import { AggregationInput } from "features/sources/createUpdateSource/ui/aggregation/aggregation_input";
import { useForm } from "react-hook-form";
import { CustomFormProvider, WithLoader } from "shared/ui";

interface IProps {
  source: ISource;
}

interface IAggreagationInputs {
  aggregation: string | null;
}

const Aggregation = ({ source }: IProps) => {
  const { data, isLoading: isLoadingData } = useGetAggregationsQuery(
    source.type === 2 ? source.id : skipToken
  );

  const [setAggregation, { isLoading: isLoadingMutation }] =
    useSetAggregationMutation();

  const Form = useForm<IAggreagationInputs>({
    defaultValues: { aggregation: source?.aggregation },
  });

  const {
    formState: { isValid },
  } = Form;

  const onSubmitHandler = async (inputs: IAggreagationInputs) => {
    try {
      const selectedAggregation = data?.find(
        (item) => item.id === inputs.aggregation
      );
      selectedAggregation &&
        (await setAggregation({
          sourceId: source?.id,
          aggregation: selectedAggregation,
        }));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CustomFormProvider onSubmit={onSubmitHandler} Form={Form}>
      <WithLoader
        isLoading={isLoadingData}
        loader={
          <Center w={"full"} h={"48px"}>
            <Spinner size={"xl"} color={"primary_btn"} />
          </Center>
        }
      >
        <HStack alignItems={"end"}>
          <AggregationInput source={source} />
          <IconButton
            icon={<CheckIcon />}
            w={"48px"}
            h={"48px"}
            aria-label="submit"
            type="submit"
            isLoading={isLoadingMutation}
            isDisabled={!isValid}
          />
        </HStack>
      </WithLoader>
    </CustomFormProvider>
  );
};

export { Aggregation };
