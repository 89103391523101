import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { skipToken } from "@reduxjs/toolkit/query";
import { ColumnDef, getCoreRowModel, getPaginationRowModel, getSortedRowModel, Row, useReactTable, } from "@tanstack/react-table";
import { Box, Button, Grid, HStack, Switch } from "@chakra-ui/react";

import { useGetCheckedDepartmentsQuery, useGetDepartmentsQuery, useSaveCheckedDepartmentsMutation } from "api";

import { IDepartment, SourcesActions, useGetSources } from "entities/sources";

import { CTable, PageWithHeading, WithLoader } from "shared/ui";
import { useAppDispatch, useAppSelector } from "shared/model";

export function SourcesFilterTable() {
  const { sourceId } = useParams();
  const dispatch = useAppDispatch();

  const company = useAppSelector(state => state.companies.current);
  const { data: sources, isLoading: isLoadingSource } = useGetSources();

  const currentSource = sources?.find(item => item.id === sourceId);

  const {
    data: departmentData,
    isLoading: isLoadingDepartments
  } = useGetDepartmentsQuery(sourceId ?? skipToken, { refetchOnMountOrArgChange: true });
  const departments = departmentData?.list

  const {
    isLoading: isLoadingCheckedDepartments
  } = useGetCheckedDepartmentsQuery(company?.id && sourceId ? {
    companyId: company?.id,
    sourceId: sourceId
  } : skipToken, { refetchOnMountOrArgChange: true });

  const checkedDepartmentsDict = useAppSelector(state => state.sources.checkedDepartments);

  //сохраняем
  const [saveCheckedDepartments, saveCheckedDepartmentsHelpers] = useSaveCheckedDepartmentsMutation();
  const isLoading = isLoadingCheckedDepartments || isLoadingDepartments || isLoadingSource;

  const onChangeDepartmentHandler = useCallback(({ name, checked }: { name: string, checked: boolean }) => {
      dispatch(SourcesActions.changeCheckedDepartments({ name, checked }));
    },
    [dispatch],
  );

  const onSaveCheckedDepartmentsHandler = useCallback(() => {
    if (!company) return;
    const checkedDepartments = [];
    //проверка на не существующие departments
    //вырезаются checkedDepartments, которых нет в списке departments
    //на случай, если у нас меняется список
    const existingDepartments: { [key: string]: boolean } = {}
    departments?.forEach(item => existingDepartments[item.name] = true);
    for (const key in checkedDepartmentsDict) {
      if (checkedDepartmentsDict[key] && existingDepartments[key]) checkedDepartments.push(key);
    }

    saveCheckedDepartments({ companyId: company.id, sourceId: sourceId || "", departments: checkedDepartments });
  }, [checkedDepartmentsDict, company, departments, saveCheckedDepartments, sourceId]);

  const onCheckAll = useCallback((checked: boolean) => {
    if (!departments) return;
    const dict = _.cloneDeep(checkedDepartmentsDict);
    if (!dict) return;
    departments.forEach(item => {
      dict[item.name] = checked;
    });
    dispatch(SourcesActions.changeAllCheckedDepartments(dict));
  }, [checkedDepartmentsDict, departments, dispatch]);

  const isAllChecked = useCallback(() => {
    if (!departments) return;
    for (const key in departments) {
      const item = departments[key];
      if (!checkedDepartmentsDict?.[item.name]) return false;
    }
    return true;
  }, [checkedDepartmentsDict, departments]);

  //тип, название, дата обновления, состояние
  const columns = useMemo(
    (): ColumnDef<IDepartment>[] => [

      { id: "department_name", accessorKey: "department_name", header: "Название", size: 400 },
      {
        id: "name", accessorKey: "name", header: "ИНН",
        // size: 1
      },
      {
        id: "checked", accessorKey: "checked",
        enableSorting: false,
        header: () => (
          <HStack w={"full"}>
            <Switch
              size={"lg"}
              isChecked={isAllChecked()}
              onChange={(e) => onCheckAll(e.target.checked)}
            />
          </HStack>
        ),
        cell: (cell) => {
          const name = cell.row.original.name;
          const checked = checkedDepartmentsDict?.[name] || false;
          return (
            <HStack w={"full"}>
              <Switch
                size={"lg"}
                isChecked={checked}
                onChange={(e) => onChangeDepartmentHandler({ name, checked: e.target.checked })}
              />
            </HStack>
          );
        },
      },
    ],
    [checkedDepartmentsDict, isAllChecked, onChangeDepartmentHandler, onCheckAll],
  );

  const table = useReactTable({
    columns,
    data: departments || [],
    enableRowSelection: true,
    manualPagination: true,
    defaultColumn: {
      minSize: 50, //enforced during column resizing
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onRowClick = (row: Row<IDepartment>) => {
    const name = row.original.name;
    const checked = checkedDepartmentsDict?.[name] || false;
    onChangeDepartmentHandler({ name, checked: !checked })
  }

  return (
    <PageWithHeading label={`${currentSource?.name ?? ""} - Списки`}>
      <Grid gridTemplateRows={"1fr auto"} h={"full"}>
        <WithLoader isLoading={isLoading}>
          <Box w={"full"} p={"0 20px 20px 20px"} h={"full"} overflowY={"auto"}>
            <CTable
              table={table}
              // isFooterDisable={true}
              onRowClick={(row) => onRowClick(row)}
            />
          </Box>
        </WithLoader>
        <Box
          w={"full"}
          p={"25px 20px"}
          backgroundColor={"primary_bg"}
          borderTop={"1px solid"}
          borderColor={"primary_btn_outline"}
        >
          <HStack>
            <Button isLoading={saveCheckedDepartmentsHelpers.isLoading} onClick={() => onSaveCheckedDepartmentsHandler()}>
              Сохранить
            </Button>
          </HStack>
        </Box>
      </Grid>
    </PageWithHeading>
  );
}