import React from "react";
import { Box, Grid } from "@chakra-ui/react";
import { CTable, SimpleWrapper, WithLoader } from "shared/ui";
import { useDetailsContext } from "features/doc";

//module
import WithLabel from "./with_label";
import { useDetailsTable } from "../model/useDetailsTable";

export default function ProductsTable() {
  const { table } = useDetailsTable();
  const { isLoading, document } = useDetailsContext();

  return (
    <WithLoader
      h={"calc(100vh - 170px)"}
      isLoading={isLoading}
    >
      <>
        <SimpleWrapper
          display={"grid"}
          gridTemplateRows={"auto 1fr"}
          h={"full"}
          pt={0}
        >
          <Box overflow={"auto"}>
            <CTable
              {...{ table }}
              headerAddon={
                <Grid
                  gridTemplateColumns={"repeat(3, 1fr)"}
                  w={"full"}
                  p={"15px 10px 15px 10px"}
                  justifyContent={"space-between"}
                  backgroundColor={"primary_th_bg"}
                  gap={"50px"}
                >
                  <WithLabel label="Поставщик">
                    {document?.seller}
                  </WithLabel>
                  <WithLabel justifySelf={"center"} label="Адрес Грузополучателя">
                    {document?.consigneeAddress}
                  </WithLabel>
                  <WithLabel justifySelf={"self-end"} label="Покупатель">
                    {document?.consignee || document?.buyer || ""}
                  </WithLabel>
                </Grid>
              }
            />
          </Box>
        </SimpleWrapper>
      </>
    </WithLoader>
  );
}