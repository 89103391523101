import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ISource } from "entities/sources";

//module
import SourceFormPass from "./pass/source_form_pass";
import SourceFormEds from "./eds/source_form_eds";
import { useCreateUpdateSource } from "../model/useCreateUpdateSource";
import { useCreateUpdateSourceEds } from "../model/useCreateUpdateSourceEds";
import { useEffect, useState } from "react";
import { AggregationForm } from "./aggregation/aggregation_form";
import { useGetAggregationsQuery, useSetAggregationMutation } from "api";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppDispatch } from "shared/model";
import { AggregationBannerActions } from "widgets/aggregation";

interface IProps {
  source?: ISource | undefined;
  disclosure?: ReturnType<typeof useDisclosure>;
  sideEffect?: () => void;
  direction?: "from" | "to";
}

export function SourceForm({
  source,
  disclosure,
  sideEffect,
  direction,
}: IProps) {
  //для вновь созданного источника (диадока) //для aggregations
  const [sourceState, setSource] = useState<ISource | null>(null);
  useEffect(() => {
    if (!source?.id) return;
    setSource(source);
  }, [source, source?.id]);
  const { data: aggregations, isLoading: isLoadingAggregation } =
    useGetAggregationsQuery(source?.type === 2 ? source.id : skipToken);
  const aggregationModal = useDisclosure();
  const [setAggregation, aggregationMutationHelper] =
    useSetAggregationMutation();
  const dispatch = useAppDispatch();
  //

  const { createUpdateSource, isLoading: isLoadingSourceMutation } =
    useCreateUpdateSource({ source });
  const { createUpdateSourceEds, isLoading: isLoadingEds } =
    useCreateUpdateSourceEds({ source });

  const onCreateUpdateSource = async (
    source: ISource,
    shouldFetchSource: boolean = true
  ) => {
    let res;
    //Диадок
    if (source.type === 2) {
      //костыль не дает баннеру вылазить на форме создания источника
      dispatch(AggregationBannerActions.setHideBannerFlag(true));
      //create
      if (!source?.id) {
        res = await createUpdateSource(source, false);
        if (res) {
          setSource(res);
          aggregationModal.onOpen();
        }
      } else {
        //update
        const aggregation = aggregations?.find(
          (aggr) => aggr.id === source?.aggregation
        );

        aggregation &&
          (await setAggregation({
            sourceId: source?.id,
            aggregation,
          }));

        res = await createUpdateSource(source, shouldFetchSource);
        disclosure?.onClose();
        //костыль не дает баннеру вылазить на форме создания источника
        dispatch(AggregationBannerActions.setHideBannerFlag(false));
      }
    } else {
      res = await createUpdateSource(source, shouldFetchSource);
      if (res) disclosure?.onClose();
    }

    //wizard nextStep
    sideEffect?.();
  };

  const onCreateUpdateSourceEds = async (
    source: ISource,
    shouldFetchSource: boolean = true
  ) => {
    const res = await createUpdateSourceEds(source, shouldFetchSource);
    sideEffect?.();
    if (res) disclosure?.onClose();
  };

  const isEDSDisabled = () => {
    if (direction === "to") return true;
    if (source?.authenticateType === 4) return true;
    return false;
  };

  const isPassDisabled = () => {
    if (source?.authenticateType === 8) return true;
    return false;
  };

  const isLoading =
    aggregationMutationHelper.isLoading ||
    isLoadingAggregation ||
    isLoadingSourceMutation;

  return (
    <>
      <Tabs
        defaultIndex={source?.authenticateType === 8 ? 1 : 0}
        variant={"form"}
        w={"full"}
      >
        <TabList w={"full"} display={"grid"} gridTemplateColumns={"1fr 1fr"}>
          <Tab isDisabled={isPassDisabled()}>Пароль</Tab>
          <Tab isDisabled={isEDSDisabled()}>ЭЦП</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <VStack gap={"15px"}>
              <SourceFormPass
                source={source}
                onSubmit={onCreateUpdateSource}
                isLoading={isLoading}
                direction={direction}
              />
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack gap={"15px"}>
              <SourceFormEds
                source={source}
                onSubmit={onCreateUpdateSourceEds}
                isLoading={isLoadingEds}
              />
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/*появится только если нет source.id т е на create */}
      <Modal
        isOpen={aggregationModal.isOpen && !source?.id}
        onClose={aggregationModal.onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Выберите ящик</ModalHeader>
          <ModalBody>
            {sourceState && (
              <AggregationForm
                source={sourceState}
                onSuccess={disclosure?.onClose}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
