import { useCreateMemberMutation, useUpdateMemberMutation } from "api";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import { IMemberInputs } from "./member_inputs";
import { IMember } from "entities/members";

import { MemberForm } from "./member_form";
import { useCallback, useMemo } from "react";

interface IProps {
  selectedMember?: IMember;
  companyId?: string;
  disclosure: ReturnType<typeof useDisclosure>;
  mode: "create" | "update";
}

export function MemberModal(
  {
    mode,
    selectedMember,
    companyId,
    disclosure,
  }: IProps) {

  const toast = useToast();
  const [createMember, createMemberHelpers] = useCreateMemberMutation();
  const [updateMember, updateMemberHelpers] = useUpdateMemberMutation();
  const isLoading = createMemberHelpers.isLoading || updateMemberHelpers.isLoading;

  const features = useMemo(() => (
    {
      create: {
        onSubmit: createMember,
        title: "Создать пользователя",
        toastDescription: "Сотрудник успешно создан",
        isClosable: true,
      },
      update: {
        onSubmit: updateMember,
        title: "Редактировать пользователя",
        toastDescription: "Сотрудник успешно обновлен",
        isClosable: true,
      }
    }), [createMember, updateMember])

  const onCreateUpdateMember = useCallback(async (data: IMemberInputs) => {
    try {
      await features[mode].onSubmit?.(data).unwrap()

      toast({
        description: features[mode].toastDescription,
        status: "success",
        isClosable: true,
      });
      disclosure.onClose();
    } catch (e) {
      console.error("Ошибка создания пользователя", e)
    }
  }, [disclosure, features, mode, toast])

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            {features[mode]?.title}
          </ModalHeader>
          <ModalBody>
            {disclosure.isOpen &&
              <MemberForm
                selectedMember={selectedMember}
                isLoading={isLoading}
                companyId={companyId}
                onClose={disclosure.onClose}
                onSubmit={onCreateUpdateMember}
              />
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}